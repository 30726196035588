import { FormEventHandler } from 'react';

import { CSSProperties } from 'styled-components';

import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';

import { BaseFormContainer } from './styles';

interface IBaseFormProps {
  readonly onSubmit: FormEventHandler;
  readonly style?: CSSProperties;
}
export const BaseForm: IFCWithChildren<IBaseFormProps> = ({
  children,
  style,
  onSubmit,
}) => {
  return (
    <BaseFormContainer
      noValidate
      onSubmit={(ev) => {
        ev.preventDefault();
        onSubmit(ev);
      }}
      {...(style && {
        style,
      })}
    >
      {children}
    </BaseFormContainer>
  );
};
