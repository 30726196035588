import { FC, useMemo } from 'react';

import { ClipLoader } from 'react-spinners';
import { IconType } from 'react-icons/lib';

import Link from 'next/link';

import { CSSProperties, useTheme } from 'styled-components';

import { IThemeColors } from '~/common/interfaces/i-theme-colors';
import { ButtonTypesEnum } from '~/common/enums/button-types-enum';
import { routes } from '~/common/constants/routes';

import { ButtonContainer, ContentContainer } from './styles';
import { Text } from '../Text';

interface IButtonProps {
  readonly text?: string;
  readonly variant?: ButtonTypesEnum;
  readonly style?: CSSProperties;
  readonly type?: 'submit' | 'button';
  readonly width?: string;
  readonly color?: keyof IThemeColors;
  readonly iconColor?: keyof IThemeColors;
  readonly borderColor?: keyof IThemeColors;
  readonly iconSize?: number;
  readonly textColor?: keyof IThemeColors;
  readonly href?: string;
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly extraContent?: JSX.Element;
  readonly onClick?: () => void;
  readonly icon?: IconType;
}
export const Button: FC<IButtonProps> = ({
  text,
  iconColor,
  style,
  variant = ButtonTypesEnum.filled,
  href,
  width = '100%',
  type = 'button',
  color = 'green',
  textColor = 'text_reverse',
  borderColor,
  loading = false,
  disabled = loading,
  iconSize = 28,
  extraContent,
  onClick,
  icon: Icon,
}) => {
  const theme = useTheme();

  const ButtonChildren = useMemo(
    () =>
      loading ? (
        <ClipLoader color={theme.input_border} loading size={20} />
      ) : (
        <ContentContainer>
          {Icon && (
            <Icon
              size={iconSize}
              {...(iconColor && {
                color: theme[iconColor],
              })}
            />
          )}

          {extraContent && extraContent}

          {text && <Text color={textColor}>{text}</Text>}
        </ContentContainer>
      ),
    [loading, theme, Icon, iconSize, iconColor, text, extraContent, textColor]
  );

  const Element = useMemo(
    () => (
      <ButtonContainer
        type={type}
        onClick={() => (disabled ? null : onClick?.())}
        width={width}
        color={disabled ? theme.grey : theme[color]}
        variant={variant}
        disabled={disabled}
        textColor={
          variant === ButtonTypesEnum.outlined ? theme[color] : theme[textColor]
        }
        {...((style || borderColor) && {
          style: {
            ...(style || {}),
            ...(borderColor && {
              border: `1px solid ${theme[borderColor]}`,
            }),
          },
        })}
      >
        {ButtonChildren}
      </ButtonContainer>
    ),
    [
      ButtonChildren,
      color,
      disabled,
      onClick,
      style,
      textColor,
      theme,
      type,
      variant,
      width,
      borderColor,
    ]
  );

  return href ? <Link href={routes[href]}>{Element}</Link> : Element;
};
