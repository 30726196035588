const prefix = '@CryptoControl';
export const cookies = {
  AUTH_TOKEN: `${prefix}-AUTH-TOKEN`,
  REFRESH_TOKEN: `${prefix}-REFRESH-TOKEN`,
  LOGGED_USER: `${prefix}-LOGGED-USER`,
  CURRENT_THEME: `${prefix}-THEME`,
  CURRENT_CURRENCY: `${prefix}-CURRENCY`,
  CURRENT_WALLETS: `${prefix}-CURRENT-WALLETS`,
  HIDE_COINS: `${prefix}-HIDE-COINS`,
  USER_SUBSCRIPTION: `${prefix}-USER-SUBSCRIPTION`,
  DCA_CURRENT_SECTION: `${prefix}-DCA_CURRENT_SECTION`,
  RADAR_CURRENT_SECTION: `${prefix}-RADAR_CURRENT_SECTION`,
  RADAR_MONITOR_NOTIFICATION_AUDIO_ACTIVE: `${prefix}-RADAR_MONITOR_NOTIFICATION_AUDIO_ACTIVE`,
  RADAR_MONITOR_CUSTOM_FILTERS_SELECTED: `${prefix}-RADAR_MONITOR_CUSTOM_FILTERS_SELECTED`,
  RADAR_MONITOR_PREDEFINED_FILTERS_SELECTED: `${prefix}-RADAR_MONITOR_PREDEFINED_FILTERS_SELECTED`,
};
