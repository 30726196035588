import styled, { css } from 'styled-components';

export const SkeletonContainer = styled.div`
  ${({ theme }) => css`
    background: linear-gradient(
      110deg,
      ${theme.background_darker} 8%,
      ${theme.background3} 18%,
      ${theme.background_darker} 33%
    );
  `}

  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;
