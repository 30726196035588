/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useCallback, useContext } from 'react';

import { useRouter } from 'next/router';

import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';
import { useModal } from '~/common/hooks/use-modal';
import {
  UserSubscriptionTypes,
  UserSubscriptionTypesFormatted,
} from '~/common/enums/user-subscription-types.enum';
import { routes } from '~/common/constants/routes';
import { SubscriptionAlertModal } from '~/common/components/SubscriptionAlertModal';

import { SubscriptionMessageModal } from '../components/SubscriptionMessageModal';

interface ISubscriptionAlertContextData {
  readonly fireSubscriptionMessageSuccess: () => void;
  readonly withAccountPermission: (
    subscription: UserSubscriptionTypes,
    userSubscription: UserSubscriptionTypes,
    callback: () => void | Promise<void>
  ) => void;
  readonly fireForbiddenModal: (
    subscription?: UserSubscriptionTypes,
    message?: string
  ) => void;
}

const SubscriptionAlertContext = createContext<ISubscriptionAlertContextData>(
  {} as ISubscriptionAlertContextData
);

const SubscriptionAlertProvider: IFCWithChildren = ({ children }) => {
  const { openModal, closeModal } = useModal();
  const router = useRouter();

  const showModal = useCallback(
    (subscription?: UserSubscriptionTypes, message?: string) => {
      openModal(SubscriptionAlertModal, {
        modalProps: {
          title: subscription
            ? `Conteúdo ${UserSubscriptionTypesFormatted[subscription]}`
            : 'Upgrade de plano necessário',
          size: 'big',
        },
        props: {
          subscription,
          message,
          onUpgrade: () => {
            closeModal();
            router.push(
              subscription
                ? `${routes.subscription}?subscription=${subscription}`
                : routes.subscription
            );
          },
        },
      });
    },
    [closeModal, openModal, router]
  );

  const fireForbiddenModal = useCallback(
    (subscription?: UserSubscriptionTypes, message?: string) => {
      showModal(
        subscription,
        !message && subscription === UserSubscriptionTypes.platinum
          ? 'Funcionalidade exclusiva para assinantes do plano Platinum.'
          : message
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fireSubscriptionMessageSuccess = useCallback(() => {
    // remove query params from url without refresh
    if (history.pushState) {
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }

    openModal(SubscriptionMessageModal, {
      modalProps: {
        showHeader: false,
      },
      props: {
        close: closeModal,
      },
    });
  }, [openModal, closeModal]);

  const withAccountPermission = (
    subscription: UserSubscriptionTypes,
    userSubscription: UserSubscriptionTypes,
    callback: () => void | Promise<void>
  ): void => {
    if (userSubscription === UserSubscriptionTypes.premium) {
      callback();
      return;
    }

    if (
      subscription === UserSubscriptionTypes.standard &&
      userSubscription === UserSubscriptionTypes.standard
    ) {
      callback();
      return;
    }

    if (subscription === UserSubscriptionTypes.free) {
      callback();
      return;
    }

    showModal(subscription);
  };

  return (
    <SubscriptionAlertContext.Provider
      value={{
        withAccountPermission,
        fireForbiddenModal,
        fireSubscriptionMessageSuccess,
      }}
    >
      {children}
    </SubscriptionAlertContext.Provider>
  );
};

const useConfigurationsAlert = () => useContext(SubscriptionAlertContext);
export { useConfigurationsAlert, SubscriptionAlertProvider };
