/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';

import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';
import { useCookies } from '~/common/hooks/use-cookies';
import { CurrencyTypesEnum } from '~/common/enums/currency-types-enum';
import { cookies } from '~/common/constants/cookies';

interface ICurrencyContextData {
  readonly currency: CurrencyTypesEnum;
  readonly setCurrency: (newCurrency: CurrencyTypesEnum) => void;
  readonly setOnChangeCallback: (
    cb: (currency: CurrencyTypesEnum) => Promise<void>
  ) => void;
}

const CurrencyContext = createContext<ICurrencyContextData>(
  {} as ICurrencyContextData
);
const CurrencyProvider: IFCWithChildren = ({ children }) => {
  const { getItem, setItem } = useCookies();
  const onChangeCallback =
    useRef<(currency: CurrencyTypesEnum) => Promise<void>>(null);

  const [currency, setCurrency] = useState<CurrencyTypesEnum>(
    (getItem(cookies.CURRENT_CURRENCY) as CurrencyTypesEnum) ||
      CurrencyTypesEnum.US
  );

  const changeCurrency = useCallback(
    (newCurrency: CurrencyTypesEnum) => {
      setCurrency(newCurrency);
      setItem(cookies.CURRENT_CURRENCY, newCurrency);
      if (onChangeCallback.current) {
        const { current: fn } = onChangeCallback;
        fn(newCurrency);
      }
    },
    [setItem]
  );

  const setOnChangeCallback = useCallback(
    (cb: (currency: CurrencyTypesEnum) => Promise<void>) => {
      onChangeCallback.current = cb;
    },
    []
  );

  useEffect(() => {
    return () => {
      onChangeCallback.current = null;
    };
  }, []);

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency: changeCurrency,
        setOnChangeCallback,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

const useCurrency = () => useContext(CurrencyContext);
export { useCurrency, CurrencyProvider };
