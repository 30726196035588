import { FC } from 'react';

import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { IoMdCheckmarkCircle } from 'react-icons/io';

import { useTheme } from 'styled-components';

import { sizes } from '~/common/constants/sizes';
import { Text } from '~/common/components/Text';
import { Button } from '~/common/components/Button';

import { SubscriptionSuccessModalContainer } from './styles';

interface ISubscriptionMessageModalProps {
  readonly close: () => void;
}
export const SubscriptionMessageModal: FC<ISubscriptionMessageModalProps> = ({
  close,
}) => {
  const theme = useTheme();

  return (
    <SubscriptionSuccessModalContainer>
      <div>
        <IoMdCheckmarkCircle color={theme.green} size={72} />
      </div>

      <Text bold size="xlg" style={{ textAlign: 'center' }}>
        Sucesso
      </Text>

      <Text size="lg" bold style={{ textAlign: 'center' }}>
        Obrigado por sua escolha!
      </Text>

      <Text
        size="md"
        bold
        color="green"
        style={{ textAlign: 'center', marginBottom: sizes.spacing.md }}
      >
        Aproveite agora todos os recursos exclusivos.
      </Text>

      <Button
        text="Fechar"
        onClick={close}
        color="green"
        textColor="white"
        width="100%"
      />
    </SubscriptionSuccessModalContainer>
  );
};
