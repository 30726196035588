import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const SubscriptionAlertModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-top: ${sizes.spacing.xlg};
    margin-bottom: ${sizes.spacing.xlg};
  }

  > span:last-of-type {
    margin-bottom: ${sizes.spacing.lg};
  }
`;
