import styled, { css } from 'styled-components';

import { sizes } from '~/common/constants/sizes';

interface IToastContainerProps {
  readonly color: string;
  readonly visible: boolean;
}
export const ToastContainer = styled.div<IToastContainerProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 500px;
  z-index: 99999999999;
  padding: ${sizes.spacing.lg};
  background-color: ${({ color }) => color};
  right: 0;
  margin-right: ${sizes.spacing.md};
  margin-top: ${sizes.spacing.md};
  border-radius: ${sizes.border.md};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  ${({ color }) => css`
    background-image: linear-gradient(
      to top,
      ${color},
      rgba(250, 250, 250, 0.25)
    );
  `}

  @media (max-width: 750px) {
    width: 95%;
  }

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${sizes.spacing.xsm};
  }

  > div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
