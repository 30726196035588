import styled from 'styled-components';

interface IMobileHeaderFakeMenuContainerProps {
  readonly open: boolean;
}
export const MobileHeaderFakeMenuContainer = styled.div<IMobileHeaderFakeMenuContainerProps>`
  transition: 0.25s;
  position: absolute;
  z-index: 99999;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  > ul {
    transition: 0.25s;
    display: flex;
    flex-direction: column;
    opacity: ${({ open }) => (open ? 1 : 0)};

    > li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
`;
