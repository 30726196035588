/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useCallback, useState } from 'react';

import { ConfirmationModalContainer } from './styles';
import { Text } from '../Text';
import { Button } from '../Button';

interface IConfirmationModalProps {
  readonly onConfirm: () => Promise<void>;
  readonly onCancel: () => void;
  readonly hideCancelButton?: boolean;
  readonly text: string;
}

export const ConfirmationModal: FC<IConfirmationModalProps> = ({
  onCancel,
  onConfirm,
  hideCancelButton,
  text,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await onConfirm().finally(() => {
        setLoading(false);
      });
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmationModalContainer>
      <Text size="md" bold>
        {text}
      </Text>

      <div>
        {!hideCancelButton && (
          <Button
            text="Cancelar"
            width="120px"
            color="background"
            textColor="white"
            borderColor="white"
            onClick={onCancel}
          />
        )}
        <Button
          text="Confirmar"
          width="120px"
          textColor="white"
          loading={loading}
          onClick={handleConfirm}
        />
      </div>
    </ConfirmationModalContainer>
  );
};
