/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  FC,
  ChangeEventHandler,
  useState,
  useMemo,
  useCallback,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';

import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { IoMdClose } from 'react-icons/io';

import Image, { StaticImageData } from 'next/image';

import { useTheme } from 'styled-components';

import { InputTypesEnum } from '~/common/enums/input-types-enum';
import { CurrencyTypesEnum } from '~/common/enums/currency-types-enum';
import { sizes } from '~/common/constants/sizes';

import { TextInputContainer } from './styles';
import { CurrencyInput } from './CurrencyInput';
import { Text } from '../Text';
import { Arrow } from '../SelectBox/styles';

type TSelectState = {
  readonly coin?: string | StaticImageData;
  readonly image?: string | StaticImageData;
  readonly open?: boolean;
  readonly async?: boolean;
  readonly resetListenerKey?: string;
  readonly reset?: () => void;
};

interface ITextInput {
  readonly textarea?: boolean;
  readonly name: string;
  readonly id?: string;
  readonly label?: string;
  readonly placeholder?: string;
  readonly value?: string | number | Date;
  readonly type?: HTMLInputTypeAttribute;
  readonly error?: boolean;
  readonly autoFocus?: boolean;
  readonly width?: string;
  readonly disabled?: boolean;
  readonly selectState?: TSelectState;
  readonly withoutAutoComplete?: boolean;
  readonly currency8digits?: boolean;
  readonly currency?: CurrencyTypesEnum;
  readonly reverseColor?: boolean;
  readonly onKeyDown?: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  readonly onChange?: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  readonly onCustomCurrencyChange?: (
    name: string,
    value: string | number
  ) => void;
  readonly onFocus?: () => void;
  readonly onBlur?: () => void;
}

export const TextInput: FC<ITextInput> = ({
  name,
  label,
  placeholder,
  value,
  type = 'text',
  id,
  textarea = false,
  autoFocus = false,
  error = false,
  disabled = false,
  reverseColor = false,
  currency = CurrencyTypesEnum.US,
  currency8digits,
  withoutAutoComplete = false,
  selectState,
  width = '100%',
  onCustomCurrencyChange,
  onKeyDown,
  onChange,
  onFocus,
  onBlur,
}) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const theme = useTheme();
  const [refValue, setRefValue] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(
    type !== InputTypesEnum.password
  );

  const switchDisabled = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  const EyeIcon = useMemo(() => (visible ? VscEye : VscEyeClosed), [visible]);

  const handleInputChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event) => {
      setRefValue(event.target.value);
      onChange(event);
    },
    [onChange]
  );

  useEffect(() => {
    if (selectState?.resetListenerKey) {
      setRefValue('');
    }
  }, [selectState?.resetListenerKey]);

  useEffect(() => {
    if (!selectState?.open) {
      setRefValue('');
    }
  }, [selectState?.open]);

  return (
    <TextInputContainer
      error={error}
      width={width}
      disabled={disabled}
      reverseColor={reverseColor}
      isColorInput={type === 'color'}
      id={id}
    >
      {label && (
        <Text
          style={{ marginBottom: sizes.spacing.xsm }}
          color={error ? 'red' : reverseColor ? 'text_reverse' : 'text'}
        >
          {label}
        </Text>
      )}

      <div>
        {(selectState?.coin || selectState?.image) && (
          <div id="select-currency-wrapper">
            <Image
              alt="currency"
              src={selectState.coin || selectState.image}
              width={26}
              height={26}
              style={{ marginRight: sizes.spacing.xsm }}
            />
          </div>
        )}

        {currency8digits ? (
          <CurrencyInput
            name={name}
            currency={currency}
            value={value as number}
            onChange={onCustomCurrencyChange}
          />
        ) : (
          <>
            {textarea ? (
              <textarea
                ref={inputRef as MutableRefObject<HTMLTextAreaElement>}
                autoFocus={autoFocus}
                name={name}
                value={
                  selectState?.async && !selectState?.resetListenerKey
                    ? refValue
                    : type === 'date'
                    ? value?.toString()
                    : value
                    ? (value as string)
                    : ''
                }
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleInputChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                {...((selectState?.coin || selectState?.image) && {
                  style: {
                    paddingLeft: '40px',
                  },
                })}
                {...(withoutAutoComplete && {
                  autoComplete: 'off',
                })}
              />
            ) : (
              <input
                ref={inputRef as MutableRefObject<HTMLInputElement>}
                type={type === 'password' && visible ? 'text' : type}
                autoFocus={autoFocus}
                name={name}
                value={
                  selectState?.async && !selectState?.resetListenerKey
                    ? refValue
                    : type === 'date'
                    ? value?.toString()
                    : value
                    ? (value as string)
                    : ''
                }
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleInputChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                {...((selectState?.coin || selectState?.image) && {
                  style: {
                    paddingLeft: '40px',
                  },
                })}
                {...(withoutAutoComplete && {
                  autoComplete: 'off',
                })}
              />
            )}
          </>
        )}

        {type === InputTypesEnum.password && (
          <div id="icon-wrapper" onClick={switchDisabled}>
            <EyeIcon
              size={16}
              color={reverseColor ? theme.text_reverse : theme.text}
            />
          </div>
        )}

        {selectState?.reset && (
          <div id="icon-wrapper">
            {value && (
              <IoMdClose
                size={16}
                color={theme.white}
                onClick={() => selectState?.reset?.()}
              />
            )}

            <Arrow
              size={16}
              open={selectState.open}
              color={theme.white}
              onClick={() => inputRef.current.focus()}
            />
          </div>
        )}
      </div>
    </TextInputContainer>
  );
};
