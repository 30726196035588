import { FC } from 'react';

import { v4 } from 'uuid';

import { ISelectOption } from '~/common/interfaces/i-select-option';

import { ISelectBoxProps, SelectBox } from '../SelectBox';
import { Modal } from '../Modal';

interface IMobileSelectionModalProps extends ISelectBoxProps {
  readonly close: () => void;
  readonly loading?: boolean;
}
export const MobileSelectionModal: FC<IMobileSelectionModalProps> = ({
  close,
  onChange,
  loading,
  ...selectProps
}) => {
  const interceptChange = (opt: ISelectOption<string>, name: string): void => {
    onChange(opt, name);
    close();
  };

  return (
    <Modal
      onClose={close}
      size="big"
      showHeader={false}
      backdropId={v4()}
      contentId="mobile-selection-modal"
      contentHeight="50%"
    >
      <SelectBox
        {...selectProps}
        isInsideMobileModal
        disabled={loading}
        inverseOptions={false}
        isMobileModal={false}
        onChange={interceptChange}
        dropdownMaxHeight={340}
      />
    </Modal>
  );
};
