import { FC, useEffect, useMemo, useState } from 'react';

import { MdCheck, MdErrorOutline, MdWarning } from 'react-icons/md';
import { IconType } from 'react-icons/lib';

import { useTheme } from 'styled-components';

import { IToastProps } from '~/common/interfaces/i-toast-props';
import { ToastTypesEnum } from '~/common/enums/toast-types-enum';

import { ToastContainer } from './styles';
import { Text } from '../Text';

export const Toast: FC<IToastProps> = ({ message, title, type }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const theme = useTheme();

  const colors: Record<ToastTypesEnum, string> = {
    [ToastTypesEnum.error]: theme.red,
    [ToastTypesEnum.warning]: theme.orange,
    [ToastTypesEnum.success]: theme.green,
  };

  const icons: Record<ToastTypesEnum, IconType> = {
    [ToastTypesEnum.error]: MdErrorOutline,
    [ToastTypesEnum.warning]: MdWarning,
    [ToastTypesEnum.success]: MdCheck,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Icon = useMemo(() => icons[type], [type]);

  useEffect(() => {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 2500);
  }, []);

  return (
    <ToastContainer color={colors[type]} visible={visible}>
      <div>
        {title && (
          <Text size="md" color="white" bold>
            {title}
          </Text>
        )}

        <Text
          size="sm"
          color="white"
          style={{
            opacity: title ? 0.75 : 1,
            ...(!title && {
              marginTop: '5px',
            }),
          }}
        >
          {message}
        </Text>
      </div>

      <div>
        <Icon color={theme.white} size={24} />
      </div>
    </ToastContainer>
  );
};
