import { api } from '../api';

interface IUseAppAuthData {
  readonly register: () => Promise<void>;
  readonly remove: () => Promise<void>;
}

export const useAppAuthData = (): IUseAppAuthData => {
  async function register() {
    try {
      const deviceIdentifier = localStorage.getItem('appAuthData-DeviceIdentifier');
      const pushOS = localStorage.getItem('PushTokenOS') ?? 'unknown';
      const pushPermissionGranted = localStorage.getItem('PushTokenPermissionGranted') == 'true';

      if (!deviceIdentifier) return;

      await api.post('/app-auth-data/register', {
        deviceIdentifier: deviceIdentifier,
        cookies: (window as any)?.getAllCookies(),
        pushOS: pushOS,
        pushPermissionGranted: pushPermissionGranted,
      });
    } catch (error) {
      //console.error('Failed to register app auth data', error);
    }
  }

  async function remove() {
    try {
      const deviceIdentifier = localStorage.getItem('appAuthData-DeviceIdentifier');

      if (!deviceIdentifier) return;

      await api.post('/app-auth-data/remove', {
        deviceIdentifier: deviceIdentifier,
      });
    } catch (error) {
      //console.error('Failed to remove app auth data', error);
    }
  }

  return {
    register,
    remove
  };
};
