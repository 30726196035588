import {
  createContext,
  useContext,
  useCallback,
  useState,
  FunctionComponent,
} from 'react';

import { IFCWithChildren } from '../interfaces/i-fc-with-children';
import { ModalRoot } from '../components/Modal/Root';
import { ModalProps } from '../components/Modal';

export interface IModalConfig {
  props?: any;
  modalProps?: ModalProps;
}

export interface IModalProvidedProps {
  component: FunctionComponent;
  config?: IModalConfig;
}

interface IModalContextData {
  openModal: (component: FunctionComponent, config?: IModalConfig) => void;
  closeModal: () => void;
}

const ModalContext = createContext<IModalContextData>({} as IModalContextData);

const ModalProvider: IFCWithChildren = ({ children }) => {
  const [modal, setModal] = useState<IModalProvidedProps | undefined>(
    undefined
  );

  const openModal = useCallback(
    (component: FunctionComponent, config?: IModalConfig) => {
      setModal({
        component,
        config,
      });
    },
    []
  );

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <ModalRoot modal={modal} handleClose={closeModal} />
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): IModalContextData {
  return useContext(ModalContext);
}

export { ModalProvider, useModal };
