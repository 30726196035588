import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const SubscriptionSuccessModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizes.spacing.sm};
  justify-content: center;
  align-items: center;
`;
