import { IoIosArrowDown } from 'react-icons/io';

import styled, { css } from 'styled-components';

import { ColorUtils } from '~/common/utils/color-utils';
import { sizes } from '~/common/constants/sizes';

export const NavWrapper = styled.div`
  position: relative;
`;
interface INavContainerProps {
  readonly selected: boolean;
  readonly highlight: boolean;
  readonly isSubMenu: boolean;
  readonly isMenu: boolean;
  readonly soon: boolean;
}
export const NavContainer = styled.div<INavContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: ${sizes.spacing.sm};
  padding-top: 0;
  padding-bottom: 0;
  border-radius: ${sizes.border.sm};
  cursor: pointer;

  ${({ soon }) =>
    soon &&
    css`
      pointer-events: none;
    `}

  > svg {
    opacity: 0.75;
    transition: 0.25s;
  }

  transition: 0.25s;
  ${({ selected, theme, isSubMenu, isMenu }) =>
    !selected &&
    !isMenu &&
    css`
      &:hover {
        background-color: ${isSubMenu ? theme.background : theme.background2};
      }
    `}

  ${({ isMenu, theme }) =>
    isMenu &&
    css`
      background-color: ${theme.background};
      background-image: linear-gradient(
        to top,
        ${theme.background},
        rgba(250, 250, 250, 0.05)
      );
    `}

  ${({ selected, theme, isSubMenu }) =>
    selected &&
    css`
      background-color: ${isSubMenu
        ? ColorUtils.hexToRgba(ColorUtils.rgbToHex(theme.green), 0.15)
        : theme.green};
      background-image: linear-gradient(
        to top,
        ${isSubMenu
          ? ColorUtils.hexToRgba(ColorUtils.rgbToHex(theme.green), 0.15)
          : theme.green},
        rgba(250, 250, 250, 0.1)
      );
    `}

  > span {
    margin-left: ${sizes.spacing.lg};
    opacity: ${({ selected }) => (selected ? 1 : 0.75)};
    transition: 0.25s;
    font-weight: 400;
  }

  ${({ highlight }) =>
    highlight &&
    css`
      background-color: ${({ theme }) => theme.blue};
      span {
        color: ${({ theme }) => theme.white};
        opacity: 1;
      }
    `}

  ${({ selected }) =>
    !selected &&
    css`
      &:hover {
        > svg {
          opacity: 1;
        }

        > span {
          opacity: 1;
          font-weight: 700;
        }
      }
    `}

  #menu-arrow {
    margin-left: auto;
  }
`;

interface IArrow {
  readonly open: boolean;
}
export const Arrow = styled(IoIosArrowDown)<IArrow>`
  transition: 0.25s;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;

interface ISubmenuContainerProps {
  readonly height: number;
  readonly open: boolean;
}

export const SubmenuContainer = styled.div<ISubmenuContainerProps>`
  background-color: ${({ theme }) => theme.background2};
  transition: 0.25s;
  height: ${({ height, open }) => (open ? `${height}px` : 0)};
  display: flex;
  flex-direction: column;
  div {
    transition: 0.25s;
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  }
`;

export const MenuBadge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${sizes.border.md};
  right: 0;
  margin-right: ${sizes.spacing.md};
  height: 25px;
  width: 80px;
  top: 0;
  z-index: 20;
  margin-top: 10px;
  user-select: none;
  text-transform: uppercase;
`;
