import { CSSProperties, useTheme } from 'styled-components';

import { IThemeColors } from '~/common/interfaces/i-theme-colors';
import { ISizesOptions } from '~/common/interfaces/i-sizes-options';
import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';
import { sizes } from '~/common/constants/sizes';

import { TextContainer } from './styles';

interface ITextProps {
  readonly style?: CSSProperties;
  readonly color?: keyof IThemeColors;
  readonly size?: keyof ISizesOptions;
  readonly bold?: boolean;
  readonly id?: string;
}
export const Text: IFCWithChildren<ITextProps> = ({
  children,
  style,
  color = 'text',
  size = 'sm',
  bold = false,
  id,
}) => {
  const theme = useTheme();

  return (
    <TextContainer
      color={theme[color]}
      size={sizes.typograph[size]}
      bold={bold}
      {...(id && { id })}
      {...(style && {
        style,
      })}
    >
      {children}
    </TextContainer>
  );
};
