/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutableRefObject, createContext, useContext, useRef } from 'react';

import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';

interface IPrivateLayoutContext {
  readonly bodyRef: MutableRefObject<HTMLDivElement>;
}

const PrivateLayoutContext = createContext<IPrivateLayoutContext>(
  {} as IPrivateLayoutContext
);

const PrivateLayoutProvider: IFCWithChildren = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <PrivateLayoutContext.Provider
      value={{
        bodyRef: ref,
      }}
    >
      {children}
    </PrivateLayoutContext.Provider>
  );
};

const usePrivateLayout = () => useContext(PrivateLayoutContext);
export { usePrivateLayout, PrivateLayoutProvider };
