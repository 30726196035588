/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useCallback, useContext, useState } from 'react';

import { ClipLoader } from 'react-spinners';

import { useTheme } from 'styled-components';
import { Backdrop } from '@mui/material';

import { IFCWithChildren } from '../interfaces/i-fc-with-children';

interface IBackdropContextProps {
  readonly active: boolean;
  readonly activateBackdrop: () => void;
  readonly desactivateBackdrop: () => void;
}

const BackdropContext = createContext<IBackdropContextProps>(
  {} as IBackdropContextProps
);

interface IBackdropProviderProps {
  readonly startsOpen?: boolean;
}

const BackdropProvider: IFCWithChildren<IBackdropProviderProps> = ({
  children,
  startsOpen = false,
}) => {
  const theme = useTheme();
  const [active, setActive] = useState<boolean>(startsOpen);

  const activateBackdrop = useCallback(() => {
    setActive(true);
  }, []);

  const desactivateBackdrop = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <BackdropContext.Provider
      value={{ active, activateBackdrop, desactivateBackdrop }}
    >
      {active && (
        <Backdrop sx={{ color: '#fff', zIndex: 500 }} open>
          <ClipLoader color={theme.green} loading size={36} />
        </Backdrop>
      )}
      {children}
    </BackdropContext.Provider>
  );
};

const useBackdrop = () => useContext(BackdropContext);

export { useBackdrop, BackdropProvider };
