/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useMemo,
  useInsertionEffect,
} from 'react';

import { useRouter } from 'next/router';

import { MobileHeaderFakeMenu } from '~/layouts/private/components/MobileHeaderFakeMenu';

import { ISelectOption } from '../interfaces/i-select-option';
import { IMobileHeaderRightButtonProps } from '../interfaces/i-mobile-header-right-button-props';
import { IFCWithChildren } from '../interfaces/i-fc-with-children';

interface IMobileHeaderContextData {
  readonly headerRightButtons: IMobileHeaderRightButtonProps[];
  readonly fakeMenu: ISelectOption<string>[];
  readonly currentFakeMenu: string;
  readonly openFakeMenu: boolean;
  readonly currentFakeMenuLabel: string;
  readonly fixTitle: string;
  readonly applyFixTitle: (title: string) => void;
  readonly setOpenFakeMenu: Dispatch<SetStateAction<boolean>>;
  readonly setHeaderRightButtons: Dispatch<
    SetStateAction<IMobileHeaderRightButtonProps[]>
  >;
  readonly setPageFakeMenu: <T extends string | number | symbol = string>(
    dispatch: Dispatch<SetStateAction<T>>,
    options: Record<T, string>,
    current?: T
  ) => void;
}

const MobileHeaderContext = createContext<IMobileHeaderContextData>(
  {} as IMobileHeaderContextData
);

const MobileHeaderProvider: IFCWithChildren = ({ children }) => {
  const router = useRouter();
  const [fakeMenu, setFakeMenu] = useState<ISelectOption[]>([]);
  const [currentFakeMenu, setCurrentFakeMenu] = useState<string>();
  const [fixTitle, setFixTitle] = useState<string>();
  const [openFakeMenu, setOpenFakeMenu] = useState<boolean>(false);
  const [headerRightButtons, setHeaderRightButtons] = useState<
    IMobileHeaderRightButtonProps[]
  >([]);

  const clearMobileHeaderState = useCallback(() => {
    setFakeMenu([]);
    setCurrentFakeMenu(null);
    setFixTitle(null);
    setOpenFakeMenu(false);
    setHeaderRightButtons([]);
  }, []);

  const applyFixTitle = useCallback((title: string) => {
    setFixTitle(title);
  }, []);

  const fakeMenuSelectCallback = useRef<Dispatch<SetStateAction<any>>>(null);

  const setPageFakeMenu = useCallback(function <
    T extends string | number | symbol = string
  >(
    dispatch: Dispatch<SetStateAction<T>>,
    options: Record<T, string>,
    current?: T
  ) {
    fakeMenuSelectCallback.current = dispatch;
    setFakeMenu(
      Object.entries(options).map(([value, label]) => ({
        value: String(value),
        label: String(label),
      }))
    );

    if (current) {
      setCurrentFakeMenu(current as string);
    }
  },
  []);

  const handleSelectFakeMenu = useCallback((opt: string) => {
    setOpenFakeMenu(false);
    setCurrentFakeMenu(opt);
    fakeMenuSelectCallback.current(opt);
  }, []);

  const currentFakeMenuLabel = useMemo(() => {
    if (currentFakeMenu && (fakeMenu || []).length) {
      const found = fakeMenu.find((opt) => opt.value === currentFakeMenu);
      if (found) {
        return found.label;
      }
    }
  }, [currentFakeMenu, fakeMenu]);

  useInsertionEffect(() => {
    clearMobileHeaderState();
  }, [router]);

  return (
    <MobileHeaderContext.Provider
      value={{
        headerRightButtons,
        currentFakeMenu,
        fakeMenu,
        openFakeMenu,
        currentFakeMenuLabel,
        fixTitle,
        applyFixTitle,
        setOpenFakeMenu,
        setHeaderRightButtons,
        setPageFakeMenu,
      }}
    >
      <MobileHeaderFakeMenu
        current={currentFakeMenu}
        open={openFakeMenu}
        options={fakeMenu}
        close={() => setOpenFakeMenu(false)}
        handleSelectFakeMenu={handleSelectFakeMenu}
      />
      {children}
    </MobileHeaderContext.Provider>
  );
};

const useMobileHeader = () => useContext(MobileHeaderContext);
export { useMobileHeader, MobileHeaderProvider };
