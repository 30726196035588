import { IThemeTypes } from '../interfaces/i-theme-types';
import { IThemeColors } from '../interfaces/i-theme-colors';

const defaultColors: Omit<
  IThemeColors,
  | 'text'
  | 'text_reverse'
  | 'background'
  | 'background_darker'
  | 'background2'
  | 'background3'
  | 'input'
  | 'input_border'
> = {
  grey: 'rgb(123, 123, 123)',
  red: 'rgb(210, 26, 81)',
  light_green: 'rgb(43, 207, 210)',
  green: 'rgb(40, 213, 89)',
  light_blue: 'rgb(100, 207, 249)',
  blue: 'rgb(58, 111, 248)',
  orange: 'rgb(242, 78, 30)',
  pink: 'rgb(169, 42, 189)',
  purple: 'rgb(217, 40, 246)',
  white: 'rgb(255, 255, 255)',
  yellow: 'rgb(255, 205, 55)',
};

export const themes: IThemeTypes = {
  dark: {
    text: 'rgb(255, 255, 255)',
    input: 'rgb(38,45,57)',
    input_border: 'rgb(38,45,57)',
    text_reverse: 'rgb(10, 10, 10)',
    background: 'rgb(31, 34, 43)',
    background2: 'rgb(16, 22, 32)',
    background3: 'rgb(38,45,57)',
    background_darker: 'rgb(7, 12, 20)',
    ...defaultColors,
  },
  light: {
    text: 'rgb(30, 30, 30)',
    input: 'rgb(255, 255, 255)',
    input_border: 'rgb(188, 190, 192)',
    text_reverse: 'rgb(255, 255, 255)',
    background: 'rgb(27, 32, 40)',
    background2: 'rgb(36, 42, 52)',
    background3: 'rgb(58,65,77)',
    background_darker: 'rgb(51, 54, 63)',
    ...defaultColors,
  },
};
