/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';

import { useRouter } from 'next/router';

import { PublicLayuout } from '~/layouts/public';
import { PrivateLayout } from '~/layouts/private';

import { IThemeTypes } from '../interfaces/i-theme-types';
import { ILoginResponse } from '../interfaces/i-login-response';
import { ILoggedUser } from '../interfaces/i-logged-user';
import { IFCWithChildren } from '../interfaces/i-fc-with-children';
import { useIsMounted } from '../hooks/use-is-mounted';
import { useCookies } from '../hooks/use-cookies';
import { useAppAuthData } from '../hooks/use-app-auth-data';
import { UserTypes } from '../enums/user-types.enum';
import { routes } from '../constants/routes';
import { cookies } from '../constants/cookies';
import { addBearerToken } from '../api';

interface IAuthContext {
  readonly loggedIn: boolean;
  readonly user: ILoggedUser;
  readonly accessToken: string;
  readonly refreshToken: string;
  readonly clearLoginState: () => void;
  readonly assignLogin: (data: ILoginResponse) => void;
  readonly logout: () => void;
  readonly initLoginState: () => void;
  readonly updateUserType: (userType: UserTypes) => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: IFCWithChildren = ({ children }) => {
  const { setItem, getItem, removeItem } = useCookies();
  const router = useRouter();
  const { remove: appAuthDataRemove } = useAppAuthData();

  const [loginData, setLoginData] = useState<ILoginResponse>(
    !!getItem(cookies.REFRESH_TOKEN) &&
      !!getItem(cookies.LOGGED_USER) &&
      !!getItem(cookies.REFRESH_TOKEN)
      ? {
          accessToken: getItem(cookies.AUTH_TOKEN),
          refreshToken: getItem(cookies.REFRESH_TOKEN),
          user: JSON.parse(getItem(cookies.LOGGED_USER)) as ILoggedUser,
        }
      : ({} as ILoginResponse)
  );

  const clearLoginState = useCallback(() => {
    setLoginData({} as ILoginResponse);
  }, []);

  const initLoginState = useCallback(() => {
    setLoginData({
      accessToken: getItem(cookies.AUTH_TOKEN),
      refreshToken: getItem(cookies.REFRESH_TOKEN),
      user: JSON.parse(getItem(cookies.LOGGED_USER)) as ILoggedUser,
    });
  }, [getItem]);

  const assignLogin = useCallback(
    (data: ILoginResponse) => {
      const { refreshToken, accessToken, user } = data;
      setItem(cookies.AUTH_TOKEN, accessToken);
      setItem(cookies.REFRESH_TOKEN, refreshToken);
      setItem(cookies.LOGGED_USER, JSON.stringify(user));
      router.push(routes.tools.portfolio);
      addBearerToken(accessToken);
      const theme = getItem(cookies.CURRENT_THEME) as keyof IThemeTypes;

      if (!theme) {
        setItem(cookies.CURRENT_THEME, 'dark');
      }
    },
    [router, getItem, setItem]
  );

  const logout = useCallback(() => {
    removeItem(cookies.AUTH_TOKEN);
    removeItem(cookies.REFRESH_TOKEN);
    removeItem(cookies.LOGGED_USER);
    removeItem(cookies.USER_SUBSCRIPTION);
    router.push(routes.auth);

    appAuthDataRemove();
  }, [appAuthDataRemove, removeItem, router]);

  const Layout = useMemo(
    () => (Object.keys(loginData).length > 0 ? PrivateLayout : PublicLayuout),
    [loginData]
  );

  const updateUserType = useCallback(
    (userType: UserTypes) => {
      const user = JSON.parse(getItem(cookies.LOGGED_USER)) as ILoggedUser;
      user.userType = userType;
      setItem(cookies.LOGGED_USER, JSON.stringify(user));
      setLoginData({
        ...loginData,
        user,
      });
    },
    [getItem, setItem, loginData]
  );

  const isMounted = useIsMounted();
  if (!isMounted) return null;

  return (
    <AuthContext.Provider
      value={{
        ...loginData,
        loggedIn: Object.keys(loginData).length > 0,
        clearLoginState,
        assignLogin,
        initLoginState,
        logout,
        updateUserType,
      }}
    >
      <Layout>{children}</Layout>
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);
export { useAuth, AuthProvider };
