import { FC } from 'react';

import { PrivacyPoliciesModalContainer } from './styles';

export interface IPrivacyPoliciesModalProps {
  readonly reverse?: boolean;
}
export const PrivacyPoliciesModal: FC<IPrivacyPoliciesModalProps> = ({
  reverse,
}) => {
  return (
    <PrivacyPoliciesModalContainer reverse={reverse}>
      <div>
        <h2>Política de Privacidade</h2>
        <hr />
      </div>

      <div>
        <h1>Introdução</h1>
        <span>
          Seja bem-vindo ao CryptoControl. Esta Política de Privacidade descreve
          como coletamos, usamos, protegemos e compartilhamos suas informações
          pessoais. Estamos comprometidos em garantir a privacidade e a
          segurança dos seus dados.
        </span>
      </div>

      <div>
        <h1>Coleta de Informações</h1>
        <span>
          Coletamos informações pessoais que você nos fornece diretamente ao se
          registrar em nosso sistema, tais como:
        </span>

        <ul>
          <li>-Nome e Sobrenome </li>
          <li>-Endereço de e-mail </li>
          <li>-Informações de pagamento</li>
        </ul>

        <span>
          Também coletamos automaticamente informações técnicas, como:
        </span>

        <ul>
          <li>-Tipo de navegador</li>
          <li>-Páginas visitadas dentro do nosso sistema</li>
          <li>-Tempo gasto no site</li>
        </ul>
      </div>

      <div>
        <h1>Uso das Informações</h1>
        <span>Utilizamos suas informações para:</span>
        <ul>
          <li>-Fornecer, operar e manter nosso sistema</li>
          <li>-Processar transações e enviar notificações</li>
          <li>-Melhorar nosso serviço e personalizar sua experiência</li>
          <li>-Enviar comunicações promocionais e atualizações</li>
          <li>-Cumprir obrigações legais e resolver disputas</li>
        </ul>
      </div>

      <div>
        <h1>Proteção de Informações</h1>
        <span>
          Adotamos medidas de segurança para proteger suas informações pessoais,
          incluindo:
        </span>

        <ul>
          <li>-Criptografia de dados</li>
          <li>-Controle de acesso restrito</li>
          <li>-Monitoramento contínuo de segurança</li>
        </ul>
      </div>

      <div>
        <h1>Compartilhamento de Informações</h1>
        <span>
          Nós não vendemos, trocamos ou alugamos suas informações pessoais .
          Podemos compartilhar suas informações com:
        </span>

        <ul>
          <li>
            Provedores de serviços terceirizados para operar nosso sistema. Por
            exemplo, processadores de pagamento.
          </li>
        </ul>
      </div>

      <div>
        <h1>Seus Direitos</h1>
        <span>Você tem o direito de:</span>
        <ul>
          <li>-Acessar e atualizar suas informações pessoais</li>
          <li>-Solicitar a exclusão de seus dados pessoais</li>
          <li>-Optar por não receber comunicações promocionais</li>
        </ul>
      </div>

      <div>
        <h1>Cookies e Tecnologias de Rastreamento</h1>
        <span>
          Utilizamos cookies e outras tecnologias de rastreamento para melhorar
          sua experiência no site, analisar o tráfego e entender de onde vêm
          nossos visitantes.
        </span>
      </div>

      <div>
        <h1>Alterações na Política de Privacidade</h1>
        <span>
          Podemos atualizar esta Política de Privacidade periodicamente.
          Notificaremos você sobre quaisquer mudanças publicando a nova política
          em nosso site e atualizando a data de revisão.
        </span>
      </div>

      <div>
        <h1>Contato</h1>
        <span>
          Se você tiver dúvidas sobre esta Política de Privacidade, entre em
          contato conosco através do e-mail <b>contact@cryptocontrol.pro</b>
        </span>
      </div>
    </PrivacyPoliciesModalContainer>
  );
};
