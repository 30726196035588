import { FC, useMemo } from 'react';

import { sizes } from '~/common/constants/sizes';
import { SelectBox } from '~/common/components/SelectBox';
import { AlertInfo } from '~/common/components/AlertInfo';

import { portfolioCalculationOptions } from '..';

import { InfoContainer } from './styles';

interface IPortfolioDataProps {
  readonly handleChangePortfolioCalculation: (
    calculation: string
  ) => Promise<void>;
  readonly portfolioCalculationMode: string;
}
export const PortfolioData: FC<IPortfolioDataProps> = ({
  handleChangePortfolioCalculation,
  portfolioCalculationMode,
}) => {
  const infoContent = useMemo(() => {
    const comparison = (
      <>
        <div>
          <b>Comparação entre os Métodos de Cálculo: Padrão e Investidor</b>
          <span>
            {`O CryptoControl oferece duas opções de cálculo para ajudá-lo a analisar o desempenho dos seus investimentos em criptomoedas. Cada método é projetado para atender a diferentes necessidades de análise e oferecer uma perspectiva única sobre seus lucros e aportes. A seguir, vamos explicar as diferenças entre os métodos "Padrão" e "Investidor", e quando você pode querer usar cada um deles.`}
          </span>
        </div>

        <div>
          <b>Método Padrão</b>
          <span>
            O método Padrão é a configuração básica e mais utilizada para
            calcular o desempenho do seu portfólio. Ele foca em fornecer uma
            visão clara do lucro acumulado ao longo do tempo, levando em
            consideração tanto os aportes quanto as retiradas.
          </span>

          <b style={{ marginTop: sizes.spacing.md }}>
            Principais Características:
          </b>
          <ul style={{ marginBottom: sizes.spacing.md }}>
            <li>
              <b>% Lucro</b> é calculado com base no lucro total em relação ao
              saldo atual menos o lucro, o que oferece uma visão precisa de
              quanto o seu portfólio cresceu em relação ao valor atual.
            </li>
            <li>
              <b>Aportes</b> são calculados como o saldo menos o lucro obtido.
              Importante destacar que os aportes variam de acordo com as
              retiradas realizadas, pois o saldo também é afetado por essas
              retiradas. Se o resultado for negativo, o sistema define como
              zero, o que ajuda a focar nos ganhos reais.
            </li>
          </ul>

          <span>
            O método Padrão é ideal para investidores que desejam uma visão
            detalhada do crescimento do seu portfólio ao longo do tempo,
            considerando tanto os ganhos quanto as perdas realizadas. É
            especialmente útil para monitorar o desempenho contínuo e avaliar se
            o portfólio está se valorizando em relação ao mercado atual.
          </span>
        </div>

        <div>
          <b>Método Investidor</b>
          <span>
            O método Investidor oferece uma abordagem alternativa, onde o foco
            está no valor total investido e no percentual de lucro em relação ao
            montante aportado. Ele é útil para investidores que desejam entender
            claramente o retorno sobre o capital investido.
          </span>

          <b style={{ marginTop: sizes.spacing.md }}>
            Principais Características:
          </b>
          <ul style={{ marginBottom: sizes.spacing.md }}>
            <li>
              <b>% Lucro</b> é calculado com base no lucro total em relação ao
              valor total aportado, o que dá uma perspectiva direta do retorno
              sobre o investimento (ROI).
            </li>
            <li>
              <b>Aportes</b> são sempre iguais ao valor total investido,
              independentemente das flutuações de mercado ou do lucro obtido.
            </li>
          </ul>

          <span>
            O método Investidor é recomendado para aqueles que preferem
            acompanhar o desempenho do portfólio com base no retorno sobre o
            investimento inicial. É útil para analisar quanto o portfólio rendeu
            em relação ao que foi investido, oferecendo uma visão mais
            tradicional de ROI, sem ser influenciado pelas oscilações de mercado
            após o investimento.
          </span>
        </div>

        <div>
          <b>Qual Método Escolher?</b>
          <ul style={{ marginBottom: sizes.spacing.md }}>
            <li>
              <b>Método Padrão:</b> Se você deseja uma análise detalhada do
              crescimento do portfólio ao longo do tempo, incluindo o impacto
              das retiradas e das flutuações do mercado, o método Padrão é o
              mais adequado.
            </li>

            <li>
              <b>Método Investidor:</b> Se o seu foco é avaliar o retorno direto
              sobre o montante investido, sem considerar as flutuações diárias
              de mercado, o método Investidor pode ser a melhor escolha.
            </li>
          </ul>

          <span>
            Ambos os métodos foram projetados para oferecer uma visão clara e
            precisa do seu portfólio, adaptando-se às suas necessidades
            específicas de investimento.
          </span>
        </div>
      </>
    );

    switch (portfolioCalculationMode) {
      case 'default': {
        return (
          <InfoContainer>
            <span>
              O CryptoControl oferece uma segunda opção de cálculo para fornecer
              uma visão alternativa do seu desempenho de investimentos em
              criptomoedas. A seguir, explicamos as regras dessa opção.
            </span>

            <div>
              <b>Quantidade de Moeda:</b>
              <span>
                Quantidade de criptomoeda que você possui atualmente, calculada
                como
              </span>
              <ul>
                <li>
                  Quantidade de Moeda = Quantidade Total de Cripto em Aportes -
                  Quantidade Total de Cripto em Retiradas
                </li>
              </ul>
            </div>

            <div>
              <b>Saldo:</b>
              <span>
                Valor total das suas criptomoedas no preço atual de mercado,
                calculado como:
              </span>
              <ul>
                <li>
                  Saldo = Quantidade de Moeda * Preço Atual da Criptomoeda
                </li>
              </ul>
            </div>

            <div>
              <b>Preço Médio:</b>
              <span>
                Preço médio que você pagou por unidade de criptomoeda, calculado
                como:
              </span>
              <ul>
                <li>
                  Preço Médio = Valor Total Aportado / Quantidade Total de
                  Cripto em Aportes
                </li>
              </ul>
            </div>

            <div>
              <b>Lucro:</b>
              <span>Soma do lucro em aportes e retiradas, calculado como:</span>
              <ul>
                <li>Lucro = Lucro em Aportes + Lucro em Retiradas</li>
              </ul>
            </div>

            <div>
              <b>% Lucro:</b>
              <span>
                Percentual de lucro em relação ao valor total aportado,
                calculado como:
              </span>
              <ul>
                <li>% Lucro = (Lucro / Valor Total Aportado) * 100</li>
              </ul>
            </div>

            <div>
              <b>Aportes:</b>
              <span>
                Valor do saldo menos o lucro obtido. Caso o cálculo resulte em
                um valor negativo, o sistema define este valor como zero. <br />
                Importante destacar que os aportes variam de acordo com as
                retiradas realizadas, pois o saldo também é afetado por essas
                retiradas.
              </span>
              <ul>
                <li>Aportes = Saldo - Lucro</li>
              </ul>
            </div>

            {comparison}
          </InfoContainer>
        );
      }

      default: {
        return (
          <InfoContainer>
            <span>
              O CryptoControl oferece uma segunda opção de cálculo para fornecer
              uma visão alternativa do seu desempenho de investimentos em
              criptomoedas. A seguir, explicamos as regras dessa opção.
            </span>

            <div>
              <b>Quantidade de Moeda:</b>
              <span>
                Quantidade de criptomoeda que você possui atualmente, calculada
                como:
              </span>
              <ul>
                <li>
                  Quantidade de Moeda = Quantidade Total de Cripto em Aportes -
                  Quantidade Total de Cripto em Retiradas
                </li>
              </ul>
            </div>

            <div>
              <b>Saldo:</b>
              <span>
                Valor total das suas criptomoedas no preço atual de mercado,
                calculado como:
              </span>
              <ul>
                <li>
                  Saldo = Quantidade de Moeda * Preço Atual da Criptomoeda
                </li>
              </ul>
            </div>

            <div>
              <b>Preço Médio:</b>
              <span>
                Preço médio que você pagou por unidade de criptomoeda, calculado
                como:
              </span>
              <ul>
                <li>
                  Preço Médio = Valor Total Aportado / Quantidade Total de
                  Cripto em Aportes
                </li>
              </ul>
            </div>

            <div>
              <b>Lucro:</b>
              <span>Soma do lucro em aportes e retiradas, calculado como:</span>
              <ul>
                <li>Lucro = Lucro em Aportes + Lucro em Retiradas</li>
              </ul>
            </div>

            <div>
              <b>% Lucro:</b>
              <span>
                Percentual de lucro em relação ao valor total aportado,
                calculado como:
              </span>
              <ul>
                <li>% Lucro = (Lucro / Valor Total Aportado) * 100</li>
              </ul>
            </div>

            <div>
              <b>Aportes:</b>
              <span>
                O valor total que você investiu em suas compras de criptomoedas.
              </span>
              <ul>
                <li>Aportes = Valor Total Aportado</li>
              </ul>
            </div>

            <div>
              <span>
                Essa segunda opção de cálculo permite uma análise diferente do
                seu portfólio, focando no percentual de lucro em relação ao
                total investido e destacando o valor total aportado como a base
                de comparação. Isso pode ser útil para investidores que desejam
                avaliar seus ganhos diretamente em relação ao montante
                investido.
              </span>
            </div>

            {comparison}
          </InfoContainer>
        );
      }
    }
  }, [portfolioCalculationMode]);

  return (
    <section>
      <SelectBox
        withoutReset
        name="portfolioCalculionMode"
        options={portfolioCalculationOptions}
        value={portfolioCalculationMode}
        dropdownMarginTop="50px"
        onChange={(opt) => handleChangePortfolioCalculation(opt?.value)}
      />

      <AlertInfo
        color="blue"
        info={infoContent}
        title={
          portfolioCalculationMode === 'default'
            ? 'Regras de Cálculo do Método Padrão'
            : 'Regras de Cálculo do Método Investidor'
        }
      />
    </section>
  );
};
