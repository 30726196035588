export enum CurrencyTypesEnum {
  US = 'US',
  EU = 'EU',
  BR = 'BR',
}

export enum CurrencyFormattedTypesEnum {
  US = 'USD',
  EU = 'EUR',
  BR = 'BRL',
}

export enum CurrencyIDTypesEnum {
  US = 2,
  EU = 3,
  BR = 1,
}
