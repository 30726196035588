/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useCallback, useInsertionEffect, useMemo, useState } from 'react';

import { IoIosArrowDown } from 'react-icons/io';
import { CgMenu } from 'react-icons/cg';

import { useRouter } from 'next/router';
import Image from 'next/image';

import { v4 } from 'uuid';
import { useTheme } from 'styled-components';

import { useMobileHeader } from '~/common/providers/mobile-header';
import { useClickOutside } from '~/common/hooks/use-click-outside';
import { sidebar } from '~/common/constants/sidebar';
import { Text } from '~/common/components/Text';

import {
  MobileHeaderContainer,
  MobileSidebarContainer,
  SidebarOverlay,
} from './styles';
import { Sidebar } from '../Sidebar';
import cclogo from '../../../../../public/images/logo-header.png';

export const MobileHeader: FC = () => {
  const theme = useTheme();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const {
    fixTitle,
    headerRightButtons,
    currentFakeMenuLabel,
    setOpenFakeMenu,
  } = useMobileHeader();

  const openSidebar = useCallback(() => {
    setSidebarOpen(true);
  }, []);

  const sidebarRef = useClickOutside<HTMLDivElement>(() => {
    setSidebarOpen(false);
  });

  const router = useRouter();
  const title = useMemo(() => {
    if (fixTitle) {
      return fixTitle;
    }

    const path = router?.asPath;
    if (!path) {
      return null;
    }

    const [menu, page] = path.substring(1).split('/');

    const menuItem = sidebar.find((men) => men.tag === menu);
    if (!menuItem) {
      return null;
    }

    const pageItem = menuItem.children.find((pg) => pg.tag === page);
    if (!pageItem) {
      return null;
    }

    return pageItem.label;
  }, [router, fixTitle]);

  const RightElement = useMemo(() => {
    if (headerRightButtons.length) {
      return headerRightButtons.map(({ callback, ref, icon: Icon }) => (
        <div onClick={callback} key={v4()} ref={ref}>
          <Icon size={24} color="white" />
        </div>
      ));
    }

    return (
      <div>
        <Image src={cclogo} alt="logo" priority quality={100} />
      </div>
    );
  }, [headerRightButtons]);

  useInsertionEffect(() => {
    setSidebarOpen(false);
  }, [router]);

  return (
    <>
      <MobileSidebarContainer open={sidebarOpen} ref={sidebarRef}>
        <Sidebar />
      </MobileSidebarContainer>
      <SidebarOverlay visible={sidebarOpen} />
      <MobileHeaderContainer>
        <div onClick={openSidebar}>
          <CgMenu size={26} color={theme.text} />
        </div>
        {!currentFakeMenuLabel && (
          <Text
            bold
            size="lg"
            {...(currentFakeMenuLabel && {
              style: {
                marginRight: 0,
              },
            })}
          >
            {title}
          </Text>
        )}

        {currentFakeMenuLabel && (
          <div
            id="fake-menu"
            onClick={() => setOpenFakeMenu(true)}
            style={{
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          >
            <Text bold size="lg">
              {`${title} - `}
              {currentFakeMenuLabel}
            </Text>
            <IoIosArrowDown size={26} color={theme.white} />
          </div>
        )}
        <div>{RightElement}</div>
      </MobileHeaderContainer>
    </>
  );
};
