import { FC } from 'react';

import { IThemeColors } from '~/common/interfaces/i-theme-colors';

import { AlertInfoContainer } from './styles';
import { Text } from '../Text';

interface IAlertInfoProps {
  readonly width?: string | number;
  readonly title?: string;
  readonly color?: keyof IThemeColors;
  readonly info: string | JSX.Element;
}
export const AlertInfo: FC<IAlertInfoProps> = ({
  width = '100%',
  title = 'Atenção',
  color = 'orange',
  info,
}) => {
  return (
    <AlertInfoContainer
      width={typeof width === 'number' ? `${width}px` : width}
      color={color}
    >
      <div id="border-left" />

      {title && (
        <Text bold size="md">
          {title}
        </Text>
      )}

      {typeof info === 'string' ? <Text size="sm">{info}</Text> : <>{info}</>}
    </AlertInfoContainer>
  );
};
