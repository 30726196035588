import { FC, useMemo } from 'react';

import { PiCrownSimple } from 'react-icons/pi';
import { MdOutlineRocket } from 'react-icons/md';
import { GrDiamond } from 'react-icons/gr';
import { CgCrown } from 'react-icons/cg';
import { IconType } from 'react-icons';

import { useTheme } from 'styled-components';

import {
  UserSubscriptionTypes,
  UserSubscriptionTypesFormatted,
} from '~/common/enums/user-subscription-types.enum';

import { SubscriptionAlertModalContainer } from './styles';
import { Text } from '../Text';
import { Button } from '../Button';

interface ISubscriptionAlertModalProps {
  readonly subscription: UserSubscriptionTypes;
  readonly message?: string;
  readonly onUpgrade: () => void;
}
export const SubscriptionAlertModal: FC<ISubscriptionAlertModalProps> = ({
  subscription,
  message = 'Torne-se assinante para ter acesso as melhores ferramentas do mercado cripto.',
  onUpgrade,
}) => {
  const theme = useTheme();
  const title = useMemo(
    () =>
      subscription === UserSubscriptionTypes.standard
        ? `Assinatura ${UserSubscriptionTypesFormatted[subscription]} necessária!`
        : subscription === UserSubscriptionTypes.premium
        ? `Assinatura Premium ou Platinum necessária!`
        : `Assinatura ${UserSubscriptionTypesFormatted[subscription]} necessária!`,
    [subscription]
  );

  const Icon: IconType = useMemo(
    () =>
      !subscription
        ? PiCrownSimple
        : {
            [UserSubscriptionTypes.standard]: MdOutlineRocket,
            [UserSubscriptionTypes.premium]: CgCrown,
            [UserSubscriptionTypes.platinum]: GrDiamond,
          }[subscription],
    [subscription]
  );

  const color: string = useMemo(
    () =>
      !subscription
        ? theme.orange
        : {
            [UserSubscriptionTypes.standard]: theme.grey,
            [UserSubscriptionTypes.premium]: theme.yellow,
            [UserSubscriptionTypes.platinum]: theme.light_blue,
          }[subscription],
    [subscription, theme]
  );

  return (
    <SubscriptionAlertModalContainer>
      <Text bold size="xlg">
        {subscription ? title : null}
      </Text>

      <Icon size={96} color={color} />

      <Text size="md" bold style={{ opacity: 0.75 }}>
        {message}
      </Text>

      {subscription === UserSubscriptionTypes.platinum && (
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px", textAlign: "center", marginBottom: "20px" }}>
            <Text color="blue" size="sm">
            Com o plano Platinum, você acessa as ferramentas mais avançadas do mundo cripto: Radar, 
            nosso sistema de alertas que faz o trabalho de mais de 100 analistas simultaneamente, 
            permitindo criar filtros com diversos indicadores e acompanhar a movimentação dos ativos sem esforço; 
            e Social Media, que monitora redes sociais dos principais influenciadores, destacando as moedas mais 
            relevantes, mantendo você sempre um passo à frente do mercado.
            </Text>
          </div>
        </>
      )}

      <Button
        width="100%"
        color="blue"
        textColor="white"
        text={
          subscription
            ? `Upgrade para a versão ${UserSubscriptionTypesFormatted[subscription]}`
            : 'Faça o upgrade'
        }
        onClick={onUpgrade}
      />
    </SubscriptionAlertModalContainer>
  );
};
