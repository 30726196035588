import { FC, useMemo } from 'react';

import { isIOS } from 'react-device-detect';
import CurrencyInputField from 'react-currency-input-field';

import { CurrencyTypesEnum } from '~/common/enums/currency-types-enum';

interface ICurrencyInputProps {
  name: string;
  value: number;
  currency?: CurrencyTypesEnum;
  onChange: (name: string, value: string | number) => void;
}
export const CurrencyInput: FC<ICurrencyInputProps> = ({
  name,
  value,
  currency = CurrencyTypesEnum.US,
  onChange,
}) => {
  const intlConfig = useMemo(
    () =>
      currency === CurrencyTypesEnum.BR
        ? { locale: 'pt-BR', currency: 'BRL' }
        : currency === CurrencyTypesEnum.US
        ? { locale: 'en-US', currency: 'USD' }
        : { locale: 'pt-BR', currency: 'EUR' },
    [currency]
  );

  const transformRawValueiOS = (iosValue: string): string => {
    return iosValue.toString().replaceAll(',', '.');
  };

  return (
    <CurrencyInputField
      name={name}
      value={value}
      decimalsLimit={8}
      onValueChange={onChange}
      intlConfig={intlConfig}
      allowNegativeValue={false}
      {...(isIOS && {
        decimalSeparator: '.',
        groupSeparator: ',',
        disableGroupSeparators: true,
        transformRawValue: transformRawValueiOS,
      })}
    />
  );
};
