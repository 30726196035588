import { FC } from 'react';

import { CSSProperties } from 'styled-components';

import { SkeletonContainer } from './styles';

interface ISkeletonProps {
  readonly width: number | string;
  readonly height: number | string;
  readonly style?: CSSProperties;
}
export const Skeleton: FC<ISkeletonProps> = ({ width, height, style }) => {
  return (
    <SkeletonContainer
      style={{
        width: typeof width === 'string' ? width : `${width}px`,
        height: typeof height === 'string' ? height : `${height}px`,
        ...(style || {}),
      }}
    />
  );
};
