import { FC, useState } from 'react';

import { useFormState } from '~/common/hooks/use-form-state';
import { TextInput } from '~/common/components/TextInput';
import { Text } from '~/common/components/Text';
import { Skeleton } from '~/common/components/Skeleton';
import { Button } from '~/common/components/Button';
import { BaseForm } from '~/common/components/BaseForm';

import { TPersonalData, TPersonalDataChange } from '..';

interface IMyAccountUserFormProps {
  readonly data: TPersonalDataChange;
  readonly onSave: (data: TPersonalDataChange) => Promise<void>;
}
const MyAccountUserForm: FC<IMyAccountUserFormProps> = ({ data, onSave }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { formData, errors, handleInputChange, handleSubmit } = useFormState(
    data,
    {
      requiredFields: ['name', 'surname'],
      onSubmit: async (req) => {
        setLoading(true);
        await onSave(req).finally(() => setLoading(false));
      },
    }
  );

  return (
    <BaseForm onSubmit={handleSubmit}>
      <TextInput
        label="Nome:"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        error={!!(errors || []).find((err) => err === 'name')}
      />
      <TextInput
        label="Sobrenome"
        name="surname"
        value={formData.surname}
        onChange={handleInputChange}
        error={!!(errors || []).find((err) => err === 'surname')}
      />
      <Button
        loading={loading}
        type="submit"
        text="Alterar"
        color="background"
        width="100%"
        textColor="white"
      />
    </BaseForm>
  );
};

interface IPersonalDataProps {
  readonly loadingData: boolean;
  readonly personalData: TPersonalData;
  readonly dataChange: TPersonalDataChange;
  readonly handleUpdatePersonalData: (
    data: TPersonalDataChange
  ) => Promise<void>;
}
export const PersonalData: FC<IPersonalDataProps> = ({
  loadingData,
  personalData,
  dataChange,
  handleUpdatePersonalData,
}) => {
  return (
    <section>
      {loadingData ? (
        <Skeleton width="100%" height={150} />
      ) : (
        <>
          <div id="info">
            <Text bold size="sm">
              Nome:
            </Text>
            <Text size="sm">
              {personalData.name}
              {personalData.surname ? ` ${personalData.surname}` : ''}
            </Text>
          </div>

          <div id="info">
            <Text bold size="sm">
              Email:
            </Text>
            <Text size="sm">{personalData.email}</Text>
          </div>

          <MyAccountUserForm
            data={dataChange}
            onSave={handleUpdatePersonalData}
          />
        </>
      )}
    </section>
  );
};
