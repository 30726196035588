/* eslint-disable import/no-extraneous-dependencies */

import Head from 'next/head';
import Script from 'next/script'
import { AppProps } from 'next/app';

import 'react-responsive-modal/styles.css';
import { ToastProvider } from '~/common/providers/toast';
import { ThemeProvider } from '~/common/providers/theme';
import { AuthProvider } from '~/common/providers/auth';

function ValidateUrl() {
  if (typeof window !== 'undefined') {
    let urlUpdated = false;
    let url = window.location.href;

    //Redirect to HTTPS
    if (url.substr(0, 5) !== 'https' && !url.includes('localhost')) {
      url = url.replace('http', 'https');
      urlUpdated = true;
    }

    //Redirect from www to non-www
    if (url.includes('www.')) {
      url = url.replace('www.', '');
      urlUpdated = true;
    }

    if (urlUpdated) {
      window.location.href = url;
    }
  }
}

function CControlApp({ pageProps, Component }: AppProps): JSX.Element {
  ValidateUrl()

  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        
        <meta name="facebook-domain-verification" content="m3vec4sb20g1zutxb39pw5mle3bdp6" />
        <meta name="apple-itunes-app" content="app-id=6479964289" />
        <title>CryptoControl</title>
      </Head>
      
      <Script strategy='beforeInteractive' src="/js/googletagmanager.js" />
      <Script strategy='beforeInteractive' src="/js/cookie-management.js" />
      <Script async src="https://js.stripe.com/v3/pricing-table.js" />

      <ThemeProvider>
        <ToastProvider>
          <AuthProvider>
            <Component {...pageProps} />
          </AuthProvider>
        </ToastProvider>
      </ThemeProvider>
    </>
  );
}

export default CControlApp;
