export class ColorUtils {
  private static format(hex: string, opacity?: number): string {
    const aRgbHex = hex.replace('#', '').match(/.{1,2}/g);
    const rgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16),
    ];

    if (opacity) {
      rgb.push(opacity);
    }

    return `(${rgb.join(',')})`;
  }

  private static componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  static rgbToHex(color: string): string {
    const [r, g, b] = color
      .split('(')[1]
      .replace(')', '')
      .replace('(', '')
      .split(',');

    return `#${this.componentToHex(Number(r))}${this.componentToHex(
      Number(g)
    )}${this.componentToHex(Number(b))}`;
  }

  static hexToRgb(hex: string): string {
    const rgb = this.format(hex);
    return `rgb`.concat(rgb);
  }

  static hexToRgba(hex: string, opacity: number): string {
    const rgba = this.format(hex, opacity);
    return `rgba`.concat(rgba);
  }
}
