/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import { FC, MutableRefObject, useEffect, useMemo, useRef } from 'react';

import { IoStarSharp } from 'react-icons/io5';

import Image from 'next/image';

import { useTheme } from 'styled-components';

import { themes } from '~/common/styles/themes';
import { useBackdrop } from '~/common/providers/backdrop';
import { IThemeColors } from '~/common/interfaces/i-theme-colors';
import { ISelectOption } from '~/common/interfaces/i-select-option';
import { useMediaQuery } from '~/common/hooks/use-media-query';

import { DropdownContainer, OptionItem } from './styles';
import { Text } from '../Text';
import { Skeleton } from '../Skeleton';
import { Button } from '../Button';

interface IConfirmationProps {
  readonly text: string;
  readonly buttonColor: keyof IThemeColors;
  readonly textColor: keyof IThemeColors;
  readonly onConfirm: () => void;
}

interface IOptionsDropdownProps {
  readonly background: keyof IThemeColors;
  readonly open: boolean;
  readonly options: ISelectOption[];
  readonly onSelect: (opt: ISelectOption) => void;
  readonly onScrollBottom?: () => Promise<void>;
  readonly justifyOptions?: 'center' | 'flex-start';
  readonly maxHeight?: number;
  readonly withBoxShadow?: boolean;
  readonly inverse?: boolean;
  readonly width?: string;
  readonly right?: string;
  readonly marginTop?: string;
  readonly overflow?: 'auto' | 'hidden';
  readonly multiple?: boolean;
  readonly withConfirmation?: IConfirmationProps;
  readonly selected?: string | string[];
  readonly loading?: boolean;
  readonly ref?: MutableRefObject<any>;
}
export const OptionsDropdown: FC<IOptionsDropdownProps> = ({
  background,
  open,
  options = [],
  justifyOptions = 'flex-start',
  width = '100%',
  maxHeight = 320,
  overflow = 'auto',
  right,
  marginTop,
  withBoxShadow = false,
  inverse = false,
  multiple = false,
  loading = false,
  withConfirmation,
  selected,
  ref = null,
  onSelect,
  onScrollBottom,
}) => {
  const isMobile = useMediaQuery('(max-width: 750px)');
  const { active: isBackdropActive } = useBackdrop();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const height = useMemo(() => {
    if (!open) {
      return 0;
    }

    if (loading) {
      return 120;
    }

    if (!options.length) {
      return 0;
    }

    let toReturn = options.length * 40;

    if (withConfirmation) {
      toReturn += 48;
    }

    return toReturn;
  }, [open, options.length, withConfirmation, loading]);

  const handleScrollChange = async (): Promise<void> => {
    if (isBackdropActive) {
      return;
    }

    if (onScrollBottom) {
      const container = scrollContainerRef.current;
      const scrollPosition = container.scrollTop + container.clientHeight;
      const scrollLimit = container.scrollHeight - 20;
      if (scrollPosition >= scrollLimit) {
        await onScrollBottom();
      }
    }
  };

  useEffect(() => {
    scrollContainerRef.current?.addEventListener('scroll', handleScrollChange);
    return () => {
      scrollContainerRef.current?.removeEventListener(
        'scroll',
        handleScrollChange
      );
    };
  }, [isBackdropActive]);

  return (
    <DropdownContainer
      ref={ref || scrollContainerRef}
      id="dropdown-container"
      open={open}
      style={{
        backgroundColor: theme[background],
        height: `${height}px`,
        maxHeight,
        width,
        overflowY: overflow,
        ...(withBoxShadow && {
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }),
        ...(marginTop && {
          marginTop,
        }),
        ...(inverse && {
          bottom: 0,
          marginTop: 0,
          marginBottom: '46px',
        }),
        ...(right && {
          right,
        }),
      }}
    >
      <>
        {loading ? (
          <>
            {[1, 2, 3].map((skl, index) => (
              <li key={String(index)}>
                <Skeleton width="100%" height={40} />
              </li>
            ))}
          </>
        ) : (
          <>
            {options.map((option) => (
              <OptionItem
                key={option.value}
                {...(isMobile
                  ? {
                      onClick: () => onSelect(option),
                    }
                  : {
                      onClick: () => onSelect(option),
                    })}
                selected={
                  multiple
                    ? ((selected || []) as string[]).includes(option.value)
                    : selected === option.value
                }
                style={{
                  justifyContent: justifyOptions,
                }}
              >
                {option.currency && (
                  <Image
                    alt="currency"
                    src={option.currency}
                    width={26}
                    height={26}
                  />
                )}
                {option.favorite && (
                  <IoStarSharp
                    width={20}
                    height={20}
                    color={themes.dark.yellow}
                  />
                )}
                {option.image && (
                  <Image alt="flag" src={option.image} width={26} height={26} />
                )}
                <Text size="sm">{option.label}</Text>
              </OptionItem>
            ))}

            {withConfirmation && (
              <li id="confirmation-li">
                <Button
                  text={withConfirmation.text}
                  color={withConfirmation.buttonColor}
                  textColor={withConfirmation.textColor}
                  onClick={withConfirmation.onConfirm}
                />
              </li>
            )}
          </>
        )}
      </>
    </DropdownContainer>
  );
};
