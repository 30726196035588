/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useMemo } from 'react';

import { useTheme } from 'styled-components';

import { ISelectOption } from '~/common/interfaces/i-select-option';
import { useClickOutside } from '~/common/hooks/use-click-outside';
import { Text } from '~/common/components/Text';

import { MobileHeaderFakeMenuContainer } from './styles';

interface IMobileHeaderFakeMenuProps {
  readonly open: boolean;
  readonly options: ISelectOption[];
  readonly current: string;
  readonly close: () => void;
  readonly handleSelectFakeMenu: (opt: string) => void;
}
export const MobileHeaderFakeMenu: FC<IMobileHeaderFakeMenuProps> = ({
  current,
  open,
  options,
  close,
  handleSelectFakeMenu,
}) => {
  const theme = useTheme();
  const ref = useClickOutside(close);

  const containerHeight = useMemo(() => {
    if (!open) {
      return 0;
    }

    if (!options || !options.length) {
      return 0;
    }

    return options.length * 50;
  }, [options, open]);

  return (
    <MobileHeaderFakeMenuContainer
      open={open}
      ref={ref}
      style={{
        height: `${containerHeight}px`,
      }}
    >
      <ul>
        {(options || []).map((option) => (
          <li
            onClick={() => handleSelectFakeMenu(option.value)}
            key={option.value}
            style={{
              backgroundColor:
                current === option.value ? theme.green : theme.background,
            }}
          >
            <Text bold size="lg">
              {option.label}
            </Text>
          </li>
        ))}
      </ul>
    </MobileHeaderFakeMenuContainer>
  );
};
