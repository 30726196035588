import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizes.spacing.md};

  b {
    font-weight: bold;
    font-size: 14px;
  }

  span {
    opacity: 0.9;
    font-size: 12px;
  }

  > div {
    display: flex;
    flex-direction: column;
    ul {
      display: flex;
      flex-direction: column;
      list-style: disc;
      li {
        font-size: 12px;
        opacity: 0.9;
        color: white;
        margin-left: 20px;
      }
    }
  }
`;
