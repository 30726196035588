import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const ConfirmationModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: ${sizes.spacing.lg};

  > div {
    margin-top: ${sizes.spacing.lg};
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacing.sm};
    button {
      width: 100%;
    }
  }
`;
