import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const MobileHeaderContainer = styled.div`
  @media (min-width: 750px) {
    display: none;
  }

  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.background_darker};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${sizes.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.background_darker};

  > span {
    margin-right: auto;
    margin-left: ${sizes.spacing.md};
  }

  > div:first-of-type {
    img {
      width: 42px;
      height: 42px;
      object-fit: contain;
    }
  }

  > div:last-of-type {
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacing.sm};
    img {
      width: 42px;
      height: 42px;
      object-fit: contain;
    }
  }

  #fake-menu {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacing.sm};
    align-items: center;
  }
`;

interface IMobileSidebarContainerProps {
  readonly open: boolean;
}
export const MobileSidebarContainer = styled.div<IMobileSidebarContainerProps>`
  position: absolute;
  height: 100%;
  width: ${({ open }) => (open ? '80%' : '0px')};
  display: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: 0.25s;
  z-index: 10000;
  > div {
    width: 100%;

    ul {
      margin-top: 0;
    }
  }
`;

interface ISidebarOverlay {
  readonly visible: boolean;
}
export const SidebarOverlay = styled.div<ISidebarOverlay>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: black;
  transition: 0.25s;
  display: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  pointer-events: none;
  opacity: ${({ visible }) => (visible ? 0.25 : 0)};
`;
