import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    outline: 0;
    padding: 0;
    font-family: 'Roboto';
    box-sizing: border-box;
  }

  html, body, #__next {
    width: 100%;
    height: 100%;
    letter-spacing: 0.5px;
    font-weight: 400;
    overflow: auto;
  }

  button {
    border: none;
    background: none;
    font-weight: bold;
    cursor: pointer;
  }

  #__next {
    z-index: 0;
    > main {
      height: 100%;
    }
  }

  h1, h2, h3, h4, h5, h6, strong, b, span, p {
    color: ${({ theme }) => theme.text};
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  #nprogress .bar {
    background: ${({ theme }) => theme.green} !important;
    height: 3px;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${({ theme }) => theme.background_darker},
      0 0 5px ${({ theme }) => theme.background_darker};
  }

  .react-responsive-modal-modal {
    width: 100% !important;
  }

  @media print {
    body {
      overflow: visible;
    }
  }
`;
