import { IoIosArrowDown } from 'react-icons/io';

import styled, { css } from 'styled-components';

import { sizes } from '~/common/constants/sizes';

interface ISelectBoxContainerProps {
  readonly error: boolean;
  readonly width: string;
}
export const SelectBoxContainer = styled.div<ISelectBoxContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  position: relative;
  > span {
    transition: 0.25s;
  }

  #fake-input {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    height: ${sizes.inputs.xlg};
    border: 1px solid ${({ theme, error }) => (error ? theme.red : theme.input)};
    background-color: ${({ theme }) => theme.input};
    color: ${({ theme }) => theme.text};
    border-radius: ${sizes.border.sm};
    padding-left: ${sizes.spacing.md};
    padding-right: ${sizes.spacing.xlg};
    transition: 0.25s;
    &:hover {
      border-color: ${({ theme }) => theme.green};
    }

    #dropdown-container {
      margin-top: 40px;
    }

    #icon-wrapper {
      position: absolute;
      right: 0;
      bottom: 6px;
      margin-right: 10px;
      opacity: 0.75;
      cursor: pointer;
    }
  }
`;

interface IArrow {
  readonly open: boolean;
}
export const Arrow = styled(IoIosArrowDown)<IArrow>`
  transition: 0.25s;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;
