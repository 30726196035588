/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import { useRouter } from 'next/router';

import { IFCWithChildren } from '../interfaces/i-fc-with-children';
import { sidebar } from '../constants/sidebar';

interface ISidebarContextProps {
  readonly current: string;
  readonly collapseMenuState: Record<string, boolean>;
  readonly setCurrent: Dispatch<SetStateAction<string>>;
  readonly handleCollapseMenu: (tag: string) => void;
}

const SidebarContext = createContext<ISidebarContextProps>(
  {} as ISidebarContextProps
);

const initialSidebarState = Object.assign(
  {},
  ...Object.entries(sidebar)
    .filter(([_, val]) => val.children?.length > 0)
    ?.map((entry) => {
      return {
        [entry[1].tag]: true,
      };
    })
);

const SidebarProvider: IFCWithChildren = ({ children }) => {
  const router = useRouter();
  const [current, setCurrent] = useState<string>();
  const [collapseMenuState, setCollapseMenuState] =
    useState<Record<string, boolean>>(initialSidebarState);

  useEffect(() => {
    setCurrent(null);
    const { asPath } = router;
    const group = asPath.substring(1).split('/')[0];
    const menu = sidebar?.find((item) => item.tag === group);
    const match = menu?.children?.find((nav) => nav.route === asPath);
    if (match) {
      setCurrent(asPath);
    } else {
      const included = menu?.children?.find((nav) =>
        asPath.includes(nav.route)
      );
      if (included) {
        setCurrent(included.route);
      }
    }
  }, [router]);

  const handleCollapseMenu = useCallback(
    (tag: string) => {
      const exists = collapseMenuState[tag];
      const toSet = { ...collapseMenuState };
      if (exists || exists === false) {
        Object.assign(toSet, {
          [tag]: !exists,
        });
      }

      setCollapseMenuState(toSet);
    },
    [collapseMenuState]
  );

  return (
    <SidebarContext.Provider
      value={{
        current,
        collapseMenuState,
        setCurrent,
        handleCollapseMenu,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

const useSidebar = () => useContext(SidebarContext);
export { useSidebar, SidebarProvider };
