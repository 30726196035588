/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useCallback, useContext, useState } from 'react';

import { IToastProps } from '../interfaces/i-toast-props';
import { IFCWithChildren } from '../interfaces/i-fc-with-children';
import { Toast } from '../components/Toast';

interface IToastContextProps {
  readonly fireToast: (data: IToastProps) => void;
}

const ToastContext = createContext<IToastContextProps>(
  {} as IToastContextProps
);

const ToastProvider: IFCWithChildren = ({ children }) => {
  const [toast, setToast] = useState<IToastProps>(null);

  const fireToast = useCallback(
    (data: IToastProps) => {
      if (!toast) {
        setToast(data);
        setTimeout(() => {
          setToast(null);
        }, data.duration || 3000);
      }
    },
    [toast]
  );

  return (
    <ToastContext.Provider value={{ fireToast }}>
      {toast && <Toast {...toast} />}
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext);
export { useToast, ToastProvider };
