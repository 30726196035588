import styled from 'styled-components';

import { themes } from '~/common/styles/themes';
import { sizes } from '~/common/constants/sizes';

export const PublicLayoutContainer = styled.div`
  background-color: #000;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${sizes.spacing.xlg};
  padding-top: 100px;

  img {
    user-select: none;
  }

  #background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 750px) {
      object-fit: cover;
    }
  }

  #cc-logo {
    z-index: 2;

    @media (max-width: 750px) {
      max-width: 300px;
      width: 100%;
    }
  }

  > span {
    margin-top: auto;
    user-select: none;
  }

  input {
    background-color: rgba(255, 255, 255, 0) !important;
    color: ${({ theme }) => theme.text} !important;
  }

  @media (max-width: 750px) {
    padding: ${sizes.spacing.sm};
    padding-top: ${sizes.spacing.xlg};
  }
`;

export const AuthBox = styled.div`
  width: 460px;
  padding: ${sizes.spacing.lg};
  z-index: 1;
  margin-top: ${sizes.spacing.xlg};
  overflow-y: auto;
  scrollbar-width: thin;

  @media (min-width: 750px) {
    border-radius: ${sizes.border.lg};
    background: rgba(255, 255, 255, 0.1); /* Fundo transparente */
    backdrop-filter: blur(10px); /* Aplica o efeito de blur */
    -webkit-backdrop-filter: blur(10px); /* Suporte para Safari */
    box-shadow: 0px 60px 50px 0px #0000008c;
  }

  @media (max-width: 750px) {
    border-radius: 0;
    width: 100%;
    padding: 0;
  }
`;

export const AuthPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: ${sizes.spacing.xlg};

  @media (max-width: 750px) {
    justify-content: flex-start;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5%;
  padding-left: 5%;

  > img {
    width: 100%;
    object-fit: contain;
  }

  @media (min-width: 750px) {
    margin-bottom: auto;
  }

  @media (max-width: 750px) {
    margin-bottom: ${sizes.spacing.xlg};
  }
`;

export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${sizes.spacing.lg};
  padding: 5%;
  max-width: 750px;
  justify-content: center;
  @media (min-width: 750px) {
    min-width: 270px;
  }

  a {
    &:hover {
      transition: 0.25s;
      color: ${({ theme }) => theme.green} !important;
      span {
        transition: 0.25s;
        color: ${({ theme }) => theme.green} !important;
      }
    }
  }

  #terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacing.sm};

    label {
      font-size: 12px;
      color: ${({ theme }) => theme.text_reverse};
    }

    b {
      font-size: 12px;
      color: ${({ theme }) => theme.text_reverse};
      transition: 0.25s;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
        filter: brightness(1.25);
      }
    }

    input {
      accent-color: ${themes.dark.green};
    }
  }
`;

export const BackgroundContainer = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.green};
  border-top-right-radius: ${sizes.border.md};
  border-bottom-right-radius: ${sizes.border.md};

  > img {
    width: 100%;
    height: 100%;
  }
`;
