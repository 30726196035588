import styled from 'styled-components';

import { ColorUtils } from '~/common/utils/color-utils';
import { IThemeColors } from '~/common/interfaces/i-theme-colors';
import { sizes } from '~/common/constants/sizes';

interface IAlertInfoContainerProps {
  readonly width: string;
  readonly color: keyof IThemeColors;
}
export const AlertInfoContainer = styled.div<IAlertInfoContainerProps>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  gap: ${sizes.spacing.sm};
  padding: ${sizes.spacing.lg};
  position: relative;
  border-top-right-radius: ${sizes.border.md};
  border-bottom-right-radius: ${sizes.border.md};
  background-color: ${({ color, theme }) =>
    ColorUtils.hexToRgba(ColorUtils.rgbToHex(theme[color]), 0.25)};

  #border-left {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: ${({ theme, color }) => theme[color]};
  }
`;
