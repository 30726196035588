import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const BaseFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${sizes.spacing.md};

  button[type='submit'] {
    margin-left: auto;
  }
`;
