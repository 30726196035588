import styled, { css } from 'styled-components';

import { ButtonTypesEnum } from '~/common/enums/button-types-enum';
import { sizes } from '~/common/constants/sizes';

interface IButtonContainerProps {
  readonly width: string;
  readonly color: string;
  readonly textColor: string;
  readonly disabled: boolean;
  readonly variant: ButtonTypesEnum;
}
export const ButtonContainer = styled.button<IButtonContainerProps>`
  width: ${({ width }) => width};
  height: ${sizes.inputs.xlg};
  border-radius: ${sizes.border.sm};
  ${({ variant, color, textColor }) =>
    variant === ButtonTypesEnum.filled &&
    css`
      background-color: ${color};
      color: ${textColor};
      transition: 0.25s;
    `}
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        opacity: 0.75;
      }
    `}
    ${({ variant, color, textColor }) =>
    variant === ButtonTypesEnum.outlined &&
    css`
      border: 1px solid ${color};
      color: ${textColor};
    `};

  svg {
    transition: 0.25s;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${sizes.spacing.xsm};
  justify-content: center;
`;
