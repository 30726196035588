import {
  CurrencyFormattedTypesEnum,
  CurrencyTypesEnum,
} from '../enums/currency-types-enum';

export const replaceLastOccurrence = (
  str: string,
  find: string,
  replace: string
): string => {
  const lastIndex = str.lastIndexOf(find);
  if (lastIndex === -1) {
    return str;
  }

  return (
    str.substring(0, lastIndex) +
    replace +
    str.substring(lastIndex + find.length)
  );
};

export const formatFormCurrency = (
  value: number,
  currency: CurrencyTypesEnum
): string => {
  if ([CurrencyTypesEnum.BR, CurrencyTypesEnum.EU].includes(currency)) {
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: CurrencyFormattedTypesEnum?.[currency] || 'BRL',
    }).format(value);
  }

  let formatted = value
    .toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace('.', ',');

  formatted = replaceLastOccurrence(formatted, ',', '.');
  return `$${formatted}`;
};

export const unformatFormCurrency = (value: string): number => {
  if (!value) return 0;
  const fixed = Number(value.replace(/\D/g, '')) / 100;

  if (value.includes('-')) {
    return Number(-fixed);
  }

  return fixed;
};
