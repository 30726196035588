import styled from 'styled-components';

export const PrivateLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  background-color: ${({ theme }) => theme.background};
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

export const DesktopSidebarContainer = styled.div`
  @media (max-width: 750px) {
    display: none;
  }
`;
