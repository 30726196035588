/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ChangeEventHandler,
  EventHandler,
  FC,
  KeyboardEventHandler,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useToast } from '~/common/providers/toast';
import { useBackdrop } from '~/common/providers/backdrop';
import { ISelectOption } from '~/common/interfaces/i-select-option';
import { useDebounce } from '~/common/hooks/use-debounce';
import { useClickOutside } from '~/common/hooks/use-click-outside';
import { ToastTypesEnum } from '~/common/enums/toast-types-enum';
import { sizes } from '~/common/constants/sizes';
import { api } from '~/common/api';

import { SelectBoxContainer } from './styles';
import { TextInput } from '../TextInput';
import { Text } from '../Text';
import { OptionsDropdown } from '../OptionsDropdown';
import { MobileSelectionModal } from '../MobileSelectionModal';

type TAsyncLoadDataProps = {
  readonly doLoading?: boolean;
  readonly memorizeValue?: boolean;
  readonly reset?: boolean;
  readonly returnIfClosed?: boolean;
  readonly withBackdrop?: boolean;
  readonly searchValue?: string;
};

type TAsyncSearchData = {
  readonly route: string;
  readonly key: string;
  readonly method?: string;
  readonly errorMessage?: string;
  readonly arrayResponseField?: string;
  readonly mapper: (apiData: any) => ISelectOption;
};

export interface ISelectBoxProps {
  readonly name: string;
  readonly asyncKeyData?: TAsyncSearchData;
  readonly label?: string;
  readonly value?: string | string[];
  readonly width?: string;
  readonly placeholder?: string;
  readonly options?: ISelectOption[];
  readonly inverseOptions?: boolean;
  readonly dropdownMarginTop?: string;
  readonly dropdownMaxHeight?: number;
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly defaultParams?: Record<string, any>;
  readonly isMobileModal?: boolean;
  readonly isInsideMobileModal?: boolean;
  readonly multi?: boolean;
  readonly favorites?: ISelectOption[];
  readonly withoutReset?: boolean;
  readonly refflectMemorizedOptionRef?: (opt: ISelectOption) => void;
  readonly onChange?: (opt: ISelectOption, name: string) => void;
}

export const SelectBox: FC<ISelectBoxProps> = (props) => {
  const {
    label,
    value,
    name,
    placeholder,
    width,
    defaultParams = {},
    dropdownMarginTop = '64px',
    disabled = false,
    dropdownMaxHeight,
    inverseOptions = false,
    isMobileModal = false,
    isInsideMobileModal = false,
    withoutReset = false,
    error = false,
    asyncKeyData,
    options,
    multi,
    favorites = [],
    refflectMemorizedOptionRef: _refflectMemorizedOptionRef,
    onChange,
  } = props;

  const { activateBackdrop, desactivateBackdrop } = useBackdrop();
  const { fireToast } = useToast();
  const [typedValue, setTypedValue] = useState<string>('');
  const [openOptions, setOpenOptions] = useState<boolean>(isInsideMobileModal);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [asyncOptions, setAsyncOptions] = useState<ISelectOption[]>([]);
  const memorizedFirstAsyncState = useRef<ISelectOption[]>(null);
  const asyncPage = useRef<number>(1);
  const typedRef = useRef<string>('');
  const killSearch = useRef<boolean>(false);
  const openRef = useRef<boolean>(false);
  const memorizedOptionRef = useRef<ISelectOption>(null);

  const refflectMemorizedOptionRef = useCallback((opt: ISelectOption) => {
    memorizedOptionRef.current = opt;
  }, []);

  const handleMultiSelectClickEvent: EventHandler<MouseEvent> = useCallback(
    (ev) => {
      if (!multi) {
        return;
      }

      const { offsetParent, name: targetName } = ev.target as any;

      if (!openOptions && targetName === name) {
        setOpenOptions(true);
        return;
      }

      if (openOptions && offsetParent) {
        const { id } = offsetParent;
        if (id !== 'dropdown-container') {
          setOpenOptions(false);
        }
      }
    },
    [name, multi, openOptions]
  );

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('click', handleMultiSelectClickEvent);
    return () => {
      // @ts-ignore
      document.removeEventListener('click', handleMultiSelectClickEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openOptions]);

  useEffect(() => {
    killSearch.current = false;
  }, [typedValue, typedRef]);

  const ref = useClickOutside(() => {
    if (!isMobileModal && !multi) {
      setTimeout(() => {
        setOpenOptions(false);
      }, 300);
    }
  });

  const finalOptions = useMemo(
    () =>
      asyncKeyData
        ? asyncOptions.length
          ? asyncOptions
          : memorizedFirstAsyncState.current || []
        : options,
    [asyncKeyData, asyncOptions, options]
  );

  const current = useMemo(() => {
    if (
      value &&
      (!multi || (multi && ((value as string[]) || []).length < 2))
    ) {
      const onOption = (finalOptions || []).find((opt) =>
        Array.isArray(value) ? opt.value === value[0] : opt.value === value
      );

      if (onOption) {
        return onOption;
      }

      if (
        Array.isArray(value)
          ? memorizedOptionRef.current?.value === value[0]
          : memorizedOptionRef.current?.value === value
      ) {
        return memorizedOptionRef.current || null;
      }

      return null;
    }

    return null;
  }, [finalOptions, memorizedOptionRef, value, multi]);

  const filteredOptions = useMemo(() => {
    if (!finalOptions || !finalOptions.length) {
      return favorites || [];
    }

    let toReturn = [...finalOptions];
    if (favorites.length) {
      toReturn = toReturn.filter(
        (where) => !favorites.find((fav) => fav.value == where.value)
      );
    }

    if (asyncKeyData) {
      return [...(favorites || []), ...toReturn];
    }

    if (typedValue && !current) {
      let filtered =
        toReturn.filter((opt) =>
          opt.label.trim().toLowerCase().includes(typedValue.toLowerCase())
        ) || [];

      if (!filtered.length) {
        filtered = toReturn;
      }

      return [...(favorites || []), ...filtered];
    }

    return [...(favorites || []), ...toReturn];
  }, [typedValue, finalOptions, current, asyncKeyData, favorites]);

  const asyncLoadData = useCallback(
    async ({
      doLoading,
      reset,
      returnIfClosed,
      withBackdrop,
      searchValue,
    }: TAsyncLoadDataProps) => {
      if (killSearch.current) return;
      if (returnIfClosed && !openRef.current) return;
      if (doLoading) setLoadingSearch(true);
      if (withBackdrop) activateBackdrop();
      if (reset) {
        setAsyncOptions([]);
      }

      try {
        const method = asyncKeyData.method || 'post';
        const payload = {
          [asyncKeyData.key]: searchValue || typedRef.current,
          Page: asyncPage.current || 1,
          ...defaultParams,
        };

        let response = null;
        if (method === 'post') {
          const { data } = await api.post(asyncKeyData.route, payload);
          response = data;
        } else {
          const { data } = await api.get(asyncKeyData.route, {
            params: payload,
          });

          response = data;
        }

        const toMap = asyncKeyData.arrayResponseField
          ? response[asyncKeyData.arrayResponseField]
          : response;

        const mapped = toMap.map(asyncKeyData.mapper) as ISelectOption[];
        if (!mapped.length && asyncPage.current > 1) {
          killSearch.current = true;
          return;
        }

        if (!memorizedFirstAsyncState.current) {
          memorizedFirstAsyncState.current = mapped;
        }

        if (!mapped || !mapped.length) {
          setAsyncOptions(memorizedFirstAsyncState.current || []);
        } else {
          setAsyncOptions((prevState) => [
            ...prevState,
            ...mapped.filter(
              (opt) => !prevState.find((prv) => prv.value === opt.value)
            ),
          ]);
        }
      } catch {
        fireToast({
          type: ToastTypesEnum.error,
          title: 'Erro',
          message:
            asyncKeyData.errorMessage || 'Houve um erro ao carregar as opções',
        });
      } finally {
        if (doLoading) setLoadingSearch(false);
        if (withBackdrop) desactivateBackdrop();
      }
    },
    [
      activateBackdrop,
      asyncKeyData,
      defaultParams,
      desactivateBackdrop,
      fireToast,
    ]
  );

  const handleIncreaseScrollPage = useCallback(async () => {
    if (!openRef.current) return;

    const next = asyncPage.current + 1;
    asyncPage.current = next;
    asyncLoadData({
      returnIfClosed: true,
      withBackdrop: true,
    });
  }, [asyncLoadData, asyncPage]);

  const handleChangeTypedValue: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (ev) => {
        setTypedValue(ev.target.value);
        if (asyncKeyData) {
          asyncPage.current = 1;
          asyncLoadData({
            doLoading: true,
            reset: true,
            searchValue: ev.target.value,
          });
        }
      },
      [asyncLoadData, asyncKeyData]
    );

  const debounceChangeInput = useDebounce(handleChangeTypedValue);

  const resetValueByInput = useCallback(() => {
    setOpenOptions(false);
    setTypedValue('');

    onChange(null, name);
    if (asyncOptions) {
      asyncPage.current = 1;
      typedRef.current = null;
      openRef.current = false;
      setAsyncOptions(memorizedFirstAsyncState.current || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, name, asyncOptions]);

  const handleSelectOption = useCallback(
    (opt: ISelectOption) => {
      onChange?.(opt, name);
      memorizedOptionRef.current = opt;
      _refflectMemorizedOptionRef?.(opt);
    },
    [onChange, _refflectMemorizedOptionRef, name]
  );

  const handleCloseOptionsByInput = useCallback(() => {
    if (!isMobileModal && !multi) {
      setTimeout(() => {
        setOpenOptions(false);
        const included = finalOptions?.find(
          (opt) => opt.label.toLowerCase() === (typedValue || '').toLowerCase()
        );

        if (!included) {
          setTypedValue(asyncKeyData ? null : '');
        }
      }, 300);
    }
  }, [finalOptions, asyncKeyData, typedValue, isMobileModal, multi]);

  const handleInputEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (ev) => {
      const { key } = ev;
      if (key === 'Enter' && filteredOptions.length > 0) {
        const [opt] = filteredOptions;

        memorizedOptionRef.current = opt;
        onChange(opt, name);
        setOpenOptions(false);
        setTypedValue('');
      }
    },
    [filteredOptions, name, onChange]
  );

  useEffect(() => {
    if (current) {
      setTypedValue('');
    }
  }, [current]);

  useEffect(() => {
    typedRef.current = typedValue;
  }, [typedValue]);

  useEffect(() => {
    openRef.current = openOptions;
    if (!openOptions) {
      asyncPage.current = 1;
      typedRef.current = null;
      openRef.current = false;
      setAsyncOptions(memorizedFirstAsyncState.current || []);
      setTypedValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openOptions]);

  useEffect(() => {
    if (asyncKeyData) {
      asyncLoadData({
        doLoading: isInsideMobileModal,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueToShow = useMemo(() => {
    if (asyncKeyData) {
      return current?.label || typedValue || null;
    }

    return typedValue || current?.label || '';
  }, [asyncKeyData, current, typedValue]);

  return (
    <SelectBoxContainer
      error={error}
      width={width}
      {...(ref && {
        ref,
      })}
    >
      {label && (
        <Text
          style={{ marginBottom: sizes.spacing.xsm }}
          color={error ? 'red' : 'text'}
        >
          {label}
        </Text>
      )}

      <div
        {...(!multi && {
          ref,
        })}
      >
        <TextInput
          withoutAutoComplete
          name={name}
          placeholder={
            !multi || (!!multi && !((value as string[]) || []).length)
              ? placeholder
              : `${(value as string[]).length} selecionados`
          }
          value={valueToShow}
          {...(!multi && {
            onFocus: () => setOpenOptions(true),
            onBlur: handleCloseOptionsByInput,
          })}
          onChange={asyncKeyData ? debounceChangeInput : handleChangeTypedValue}
          onKeyDown={handleInputEnter}
          disabled={
            withoutReset ? false : disabled || !multi ? !!current : false
          }
          autoFocus={isInsideMobileModal}
          selectState={{
            coin: current?.currency,
            image: current?.image,
            open: openOptions,
            async: !!asyncKeyData,
            resetListenerKey: current?.value,
            ...(!withoutReset && {
              reset: multi ? null : resetValueByInput,
            }),
          }}
        />
      </div>

      {isMobileModal && !isInsideMobileModal ? (
        <>
          {openOptions ? (
            <MobileSelectionModal
              close={() => setOpenOptions(false)}
              loading={loadingSearch}
              refflectMemorizedOptionRef={refflectMemorizedOptionRef}
              {...props}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {multi ? (
            <OptionsDropdown
              withBoxShadow
              multiple
              loading={loadingSearch}
              background="input"
              onSelect={handleSelectOption}
              maxHeight={dropdownMaxHeight}
              marginTop={dropdownMarginTop}
              inverse={inverseOptions}
              open={openOptions}
              selected={value}
              options={filteredOptions}
            />
          ) : (
            <OptionsDropdown
              background="input"
              open={openOptions}
              options={filteredOptions}
              inverse={inverseOptions}
              onSelect={handleSelectOption}
              marginTop={dropdownMarginTop}
              maxHeight={dropdownMaxHeight}
              loading={loadingSearch}
              withBoxShadow
              {...(asyncKeyData && {
                onScrollBottom: handleIncreaseScrollPage,
              })}
            />
          )}
        </>
      )}
    </SelectBoxContainer>
  );
};
