import styled, { css, keyframes } from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export type ModalSize = 'sm' | 'md' | 'lg' | 'xlg' | 'big' | 'full';

interface ContentProps {
  size: ModalSize;
  rotate: boolean;
  killMaxHeight: boolean;
}

const backdropAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const modalSize: Record<ModalSize, string> = {
  full: '95%',
  big: '1200px',
  xlg: '850px',
  lg: '700px',
  md: '550px',
  sm: '400px',
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 20;
  overflow: hidden;
  animation: ${backdropAnimation} 0.3s;
  transition: opacity 0.3s;
  padding: ${sizes.spacing.md};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 750px) {
    pointer-events: none;
    padding: 0;
  }
`;

export const Content = styled.div<ContentProps>`
  position: relative;
  max-width: ${({ size }) => modalSize[size]};
  width: 100%;
  background-color: #f9f9f9;
  border-radius: ${sizes.border.md};
  box-shadow: 3px 3px 15px #00000010;
  background-color: ${({ theme }) => theme.background2};
  overflow: hidden;
  margin: ${sizes.spacing.md};
  transition: all 0.3s;
  z-index: 40;
  cursor: default;
  > div {
    height: 100%;
    background-color: ${({ theme }) => theme.background2};
    > div {
      height: 100%;
      overflow: auto;
      padding: ${sizes.spacing.lg};
      ${({ killMaxHeight }) =>
        !killMaxHeight &&
        css`
          max-height: 730px;
        `}
    }
  }

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(90deg);
    `}

  @media (max-width: 750px) {
    pointer-events: all;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${sizes.spacing.md};
  margin-bottom: ${sizes.spacing.md};
  border-bottom: 1px solid #ddd;
  gap: ${sizes.spacing.sm};
  background-color: ${({ theme }) => theme.background2};

  h1 {
    font-size: 14px;
    color: ${({ theme }) => theme.text};
    text-transform: uppercase;
    line-height: 13px;
    flex: 1;
    font-weight: bold;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      opacity: 0.6;
    }

    svg {
      height: 16px;
      width: 16px;
      color: ${({ theme }) => theme.text};
      stroke-width: 1px;
    }
  }
`;
