export enum UserSubscriptionTypes {
  free = 'free',
  standard = 'standard',
  premium = 'premium',
  vip = 'vip',
  admin = 'admin',
  platinum = 'platinum',
}

export enum UserSubscriptionTypesFormatted {
  free = 'Grátis',
  standard = 'Standard',
  premium = 'Premium',
  vip = 'VIP',
  admin = 'Admin',
  platinum = 'Platinum',
}
