import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const MyAccountModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizes.spacing.lg};

  > section {
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacing.xsm};

    > hr {
      margin-top: ${sizes.spacing.sm};
      margin-bottom: ${sizes.spacing.sm};
    }

    #info {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${sizes.spacing.xsm};

      > span:last-of-type {
        opacity: 0.75;
      }
    }

    form {
      margin-top: ${sizes.spacing.lg};
    }
  }

  > small {
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacing.xsm};
    justify-content: center;
  }

  > ul {
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacing.sm};

    li {
      font-size: 14px;
      cursor: pointer;
      > span {
        transition: 0.25s;
        &:hover {
          text-decoration: underline;
          color: ${({ theme }) => theme.green};
        }
      }
    }
  }
`;

export const CleanDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin-bottom: ${sizes.spacing.sm};
  }
`;
