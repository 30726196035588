/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { ILoginRequest } from '~/pages-modules/auth/interfaces/i-login-request';

import { useToast } from '~/common/providers/toast';
import { useBackdrop } from '~/common/providers/backdrop';
import { ISelectOption } from '~/common/interfaces/i-select-option';
import { UserTypes } from '~/common/enums/user-types.enum';
import { UserSubscriptionTypes } from '~/common/enums/user-subscription-types.enum';
import { ToastTypesEnum } from '~/common/enums/toast-types-enum';
import { routes } from '~/common/constants/routes';
import { Text } from '~/common/components/Text';
import { api } from '~/common/api';

import { SubscriptionData } from './SubscriptionData';
import { MyAccountModalContainer } from './styles';
import { PortfolioData } from './PortfolioData';
import { PersonalData } from './PersonalData';

export type TSubscriptionData = {
  dataLiberacao: string;
  dataValidade: string;
  status: string;
  assinaturaId: string;
};

export type TPersonalDataChange = {
  name: string;
  surname: string;
};

export type TPersonalData = {
  name: string;
  surname?: string;
  email: string;
};

export const portfolioCalculationOptions: ISelectOption[] = [
  { value: 'default', label: 'Método Padrão' },
  { value: 'sumTotal', label: 'Método Investidor' },
];

type TView = 'personal-data' | 'subscription' | 'portfolio';

interface IMyAccountModalProps {
  readonly onSubscriptionRedirect: () => void;
  readonly onSubscriptionCancel: () => void;
  readonly close: () => void;
  readonly subscription: UserSubscriptionTypes;
  readonly userType: UserTypes;
}
export const MyAccountModal: FC<IMyAccountModalProps> = ({
  onSubscriptionRedirect,
  close,
  subscription,
  userType,
}) => {
  const { fireToast } = useToast();
  const { activateBackdrop, desactivateBackdrop } = useBackdrop();
  const router = useRouter();
  const [openCleanAccountData, setOpenCleanAccountData] =
    useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [view, setView] = useState<TView>('personal-data');
  const [dataChange, setDataChange] = useState<TPersonalDataChange>(
    {} as TPersonalDataChange
  );
  const [personalData, setPersonalData] = useState<TPersonalData>(
    {} as TPersonalData
  );
  const [subscriptionData, setSubscriptionData] = useState<TSubscriptionData>(
    {} as TSubscriptionData
  );

  const [portfolioCalculationMode, setPortfolioCalculationMode] =
    useState<string>();

  const [cancelAccountData, setCancelAccountData] = useState<
    Partial<ILoginRequest>
  >({} as Partial<ILoginRequest>);

  const handleManageSubscription = async () => {
    try {
      fireToast({
        type: ToastTypesEnum.success,
        message:
          'Redirecionando para o portal de gerenciamento da assinatura, aguarde...',
      });
      setLoadingData(true);
      window.open(
        (await api.get('/Stripe/get-customer-portal-url')).data,
        '_self'
      );
    } catch (e) {
      setLoadingData(false);
      fireToast({
        type: ToastTypesEnum.error,
        message:
          'Houve um erro ao carregar o portal de gerenciamento da assinatura',
      });
    }
  };

  const handleChangeCancelDataRequestData: ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      setCancelAccountData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    }, []);

  const handleDeleteAccountData = useCallback(async () => {
    if (!cancelAccountData.password) {
      fireToast({
        type: ToastTypesEnum.warning,
        message: 'Atenção, confirme sua senha para essa operação',
      });

      return;
    }

    activateBackdrop();
    try {
      await api.delete('/MeusDados', {
        data: {
          password: cancelAccountData.password,
        },
      });

      close();
      router.push(`${routes.tools.portfolio}?withAccountDeleteMessage=true`);
    } catch {
      fireToast({
        type: ToastTypesEnum.error,
        message:
          'Houve um erro para apagar os seus dados, entre em contato com o suporte para esclarecimentos.',
      });
    } finally {
      desactivateBackdrop();
    }
  }, [
    activateBackdrop,
    cancelAccountData.password,
    router,
    close,
    desactivateBackdrop,
    fireToast,
  ]);

  const handleChangePortfolioCalculation = useCallback(
    async (calculation: string) => {
      try {
        await api.patch('/UserParams', {
          portifolioMethod: calculation,
        });

        setPortfolioCalculationMode(calculation);
      } catch {
        setPortfolioCalculationMode(portfolioCalculationMode);
        fireToast({
          type: ToastTypesEnum.error,
          message: 'Houve um erro inesperado ao alterar a forma de calculo',
        });
      }
    },
    [portfolioCalculationMode]
  );

  const handleUpdatePersonalData = useCallback(
    async (data: TPersonalDataChange) => {
      try {
        const { data: response } = await api.post('/MeusDados/Alterar', {
          Nome: data.name,
          Sobrenome: data.surname,
        });

        if (response.erroMensagem) {
          throw new Error();
        }

        fireToast({
          type: ToastTypesEnum.success,
          message: 'Informações atualizadas com sucesso',
        });
      } catch {
        fireToast({
          type: ToastTypesEnum.error,
          message: 'Houve um erro ao alterar os dados',
        });
      }
    },
    [fireToast]
  );

  useEffect(() => {
    (async () => {
      try {
        const {
          data: {
            value: { plano, userParams: userParamsResponse, ...personal },
          },
        } = await api.post('/MeusDados/Carregar');

        setPersonalData({
          email: personal.email,
          name: personal.nome,
          surname: personal.sobrenome,
        });

        setSubscriptionData(plano);
        setDataChange({
          name: personal?.nome,
          surname: personal?.sobrenome,
        });

        setPortfolioCalculationMode(
          userParamsResponse?.portifolioMethod || 'default'
        );
      } catch {
        fireToast({
          type: ToastTypesEnum.error,
          message: 'Houve um erro ao carregar as informações da conta',
        });
      } finally {
        setLoadingData(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Content = useMemo(() => {
    switch (view) {
      case 'personal-data': {
        return (
          <PersonalData
            loadingData={loadingData}
            personalData={personalData}
            dataChange={dataChange}
            handleUpdatePersonalData={handleUpdatePersonalData}
          />
        );
      }

      case 'subscription': {
        return (
          <SubscriptionData
            userType={userType}
            subscription={subscription}
            loadingData={loadingData}
            cancelAccountData={cancelAccountData}
            subscriptionData={subscriptionData}
            openCleanAccountData={openCleanAccountData}
            handleDeleteAccountData={handleDeleteAccountData}
            handleManageSubscription={handleManageSubscription}
            onSubscriptionRedirect={onSubscriptionRedirect}
            setOpenCleanAccountData={setOpenCleanAccountData}
            handleChangeCancelDataRequestData={
              handleChangeCancelDataRequestData
            }
          />
        );
      }

      case 'portfolio': {
        return (
          <PortfolioData
            portfolioCalculationMode={portfolioCalculationMode}
            handleChangePortfolioCalculation={handleChangePortfolioCalculation}
          />
        );
      }

      default: {
        return null;
      }
    }
  }, [
    userType,
    subscription,
    view,
    loadingData,
    personalData,
    dataChange,
    cancelAccountData,
    subscriptionData,
    openCleanAccountData,
    portfolioCalculationMode,
    handleChangePortfolioCalculation,
    handleUpdatePersonalData,
    handleDeleteAccountData,
    handleManageSubscription,
    onSubscriptionRedirect,
    handleChangeCancelDataRequestData,
  ]);

  return (
    <MyAccountModalContainer>
      <ul>
        <li onClick={() => setView('personal-data')}>
          <Text color={view === 'personal-data' ? 'green' : 'white'}>
            Dados Pessoais
          </Text>
        </li>
        {userType !== UserTypes.admin && (
          <li onClick={() => setView('subscription')}>
            <Text color={view === 'subscription' ? 'green' : 'white'}>
              Assinatura
            </Text>
          </li>
        )}
        <li onClick={() => setView('portfolio')}>
          <Text color={view === 'portfolio' ? 'green' : 'white'}>
            Cálculos Portifólio
          </Text>
        </li>
      </ul>

      <hr />

      {Content}
      {[UserSubscriptionTypes.premium, UserSubscriptionTypes.platinum].includes(
        subscription
      ) && (
        <small>
          <Text size="xsm">Email para contato:</Text>
          <Text size="xsm" color="blue">
            contact@cryptocontrol.pro
          </Text>
        </small>
      )}
    </MyAccountModalContainer>
  );
};
