/* eslint-disable import/no-cycle */
import { destroyCookie, parseCookies } from 'nookies';
import axios, { AxiosInstance } from 'axios';

import { env } from '../constants/env';
import { cookies } from '../constants/cookies';

export class AuthTokenError extends Error {
  constructor() {
    super('Error with authentication token.');
  }
}

export function setupApi(ctx = undefined): AxiosInstance {
  const parsedCookies = parseCookies(ctx);
  const { API_URL } = env;

  const api = axios.create({
    baseURL: API_URL,
    headers: {
      ...(parsedCookies &&
        parsedCookies[cookies.AUTH_TOKEN] && {
          Authorization: `Bearer ${parsedCookies[cookies.AUTH_TOKEN]}`,
        }),
    },
  });

  api.interceptors.response.use(
    (success) => success,
    (error) => {
      if (error.response?.status === 401) {
        if (error.response?.data?.code === 'token_expired') {
          if (typeof document !== 'undefined') {
            destroyCookie(null, cookies.AUTH_TOKEN, { path: '/' });
            destroyCookie(null, cookies.REFRESH_TOKEN, { path: '/' });
            destroyCookie(null, cookies.LOGGED_USER, { path: '/' });
            destroyCookie(null, cookies.USER_SUBSCRIPTION, { path: '/' });
          } else {
            return Promise.reject(new AuthTokenError());
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export const api = setupApi();

export const addBearerToken = (token: string): void => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};
