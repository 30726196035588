import styled, { css } from 'styled-components';

import { ColorUtils } from '~/common/utils/color-utils';
import { sizes } from '~/common/constants/sizes';

interface IDropdownContainerProps {
  readonly open: boolean;
}
export const DropdownContainer = styled.ul<IDropdownContainerProps>`
  position: absolute;
  height: 500px;
  z-index: 10;
  transition: 0.25s;
  scrollbar-width: thin;
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  ${({ open }) =>
    !open &&
    css`
      li {
        display: none;
      }
    `}

  #confirmation-li {
    padding: ${sizes.spacing.sm};
    padding-top: 0;
  }
`;

interface IOptionItemProps {
  readonly selected: boolean;
}
export const OptionItem = styled.li<IOptionItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${sizes.spacing.xsm};
  min-height: 40px;
  padding-left: ${sizes.spacing.sm};
  padding-right: ${sizes.spacing.sm};
  transition: 0.25s;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }

  input[type='checkbox'] {
    accent-color: ${({ theme }) => theme.green};
  }

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${ColorUtils.hexToRgba(
        ColorUtils.rgbToHex(theme.green),
        0.25
      )};
    `}
`;
