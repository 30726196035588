import styled from 'styled-components';

import { themes } from '~/common/styles/themes';
import { sizes } from '~/common/constants/sizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizes.spacing.xlg};
  padding: ${sizes.spacing.lg};

  b {
    color: ${themes.dark.green};
  }
`;
