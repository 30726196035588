import {
  useState,
  useCallback,
  ChangeEventHandler,
  FormEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';

import { unformatFormCurrency } from '../utils/format-form-currency';
import { ISelectOption } from '../interfaces/i-select-option';

interface IUseFormStateConfigListener<T = any> {
  readonly field: keyof T;
  readonly target: keyof T | Array<keyof T>;
  readonly change: (data: T) => any;
}

interface IUseFormStateConfig<T = any> {
  readonly currencies?: string[];
  readonly async?: boolean;
  readonly requiredFields?: Array<keyof T>;
  readonly validations?: Record<keyof T, RegExp>;
  readonly listeners?: IUseFormStateConfigListener<T>[];
  readonly generalListener?: (data: T) => T;
  readonly onSubmit?: (data: T) => Promise<void> | void;
}

type TUseFormstateData<T> = {
  readonly formData: T;
  readonly loading: boolean;
  readonly errors: Array<keyof T>;
  readonly handleCheckChange: ChangeEventHandler<HTMLInputElement>;
  readonly handleInputChange: ChangeEventHandler<HTMLInputElement>;
  readonly handleSelectChange: (opt: ISelectOption, name: string) => void;
  readonly handleCustomCurrencyInput: (
    value: string | number,
    name: string
  ) => void;
  readonly handleSubmit: FormEventHandler;
  readonly setFormData: Dispatch<SetStateAction<T>>;
};
export function useFormState<T = any>(
  initialValue?: T,
  config?: IUseFormStateConfig<T>
): TUseFormstateData<T> {
  const [fieldsWithError, setFieldsWithError] = useState<Array<keyof T>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<T>(initialValue || ({} as T));

  const handleListenerChange = useCallback(
    (updated: T, field: string): T => {
      const listeners =
        config.listeners.filter((list) => list.field === field) || [];
      const reupdated: T = { ...updated };
      if (listeners.length) {
        for (const listener of listeners) {
          const { target, change } = listener;
          const targetUpdated = change(updated);
          if (Array.isArray(target)) {
            let index = 0;
            for (const _target of target) {
              Object.assign(reupdated, {
                [_target]: targetUpdated[index],
              });
              index++;
            }
          } else
            Object.assign(reupdated, {
              [target]: targetUpdated,
            });
        }
      }

      return reupdated;
    },
    [config]
  );

  const handleSelectChange = useCallback(
    (opt: ISelectOption, name: string) => {
      let updated: T = {
        ...formData,
        [name]: opt?.value,
      };

      if (config?.listeners?.length) {
        updated = handleListenerChange(updated, name);
      }

      if (config?.generalListener) {
        updated = config.generalListener(updated);
      }

      setFormData(updated);
    },
    [config, formData, handleListenerChange]
  );

  const handleCheckChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, checked } = event.target;
      let updated: T = {
        ...formData,
        [name]: !checked,
      };

      if (config?.listeners?.length) {
        updated = handleListenerChange(updated, name);
      }

      if (config?.generalListener) {
        updated = config.generalListener(updated);
      }

      setFormData(updated);
    },
    [config, formData, handleListenerChange]
  );

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;
      let updated: T = {
        ...formData,
        [name]: config?.currencies?.includes(name)
          ? unformatFormCurrency(value)
          : value,
      };

      if (config?.listeners?.length) {
        updated = handleListenerChange(updated, name);
      }

      if (config?.generalListener) {
        updated = config.generalListener(updated);
      }

      setFormData(updated);
    },
    [config, formData, handleListenerChange]
  );

  const handleCustomCurrencyInput = useCallback(
    (value: string | number, name: string) => {
      let updated: T = {
        ...formData,
        [name]: value,
      };

      if (config?.listeners?.length) {
        updated = handleListenerChange(updated, name);
      }

      if (config?.generalListener) {
        updated = config.generalListener(updated);
      }

      setFormData(updated);
    },
    [config, formData, handleListenerChange]
  );

  const handleSubmit: FormEventHandler = useCallback(
    async (ev) => {
      ev.preventDefault();
      if (config?.onSubmit) {
        if (config.requiredFields?.length) {
          const errorsToSet: Array<keyof T> = [];
          for (const key of config.requiredFields) {
            const found = formData[key];
            if (!found) {
              errorsToSet.push(key);
            }
          }

          if (errorsToSet.length) {
            setFieldsWithError(errorsToSet);
            return;
          }

          if (fieldsWithError.length) {
            setFieldsWithError([]);
          }
        }

        if (config.validations) {
          const errorsToSet: Array<keyof T> = [];
          for (const [field, validation] of Object.entries(
            config.validations
          )) {
            const found = formData[field];
            if (!(validation as RegExp).test(found)) {
              errorsToSet.push(field as keyof T);
            }
          }

          if (errorsToSet.length) {
            setFieldsWithError(errorsToSet);
            return;
          }

          if (fieldsWithError.length) {
            setFieldsWithError([]);
          }
        }

        if (config.async) setLoading(true);
        await config?.onSubmit?.(formData);
        if (config.async) setLoading(false);
      }
    },
    [config, fieldsWithError, formData]
  );

  return {
    formData,
    loading,
    errors: fieldsWithError,
    handleInputChange,
    handleSelectChange,
    handleCheckChange,
    handleSubmit,
    handleCustomCurrencyInput,
    setFormData,
  };
}
