import styled from 'styled-components';

interface ITextContainerProps {
  readonly color: string;
  readonly size: string;
  readonly bold: boolean;
}
export const TextContainer = styled.span<ITextContainerProps>`
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'regular')};
`;
