import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

interface IMenuContainerProps {
  readonly height: number;
  readonly open: boolean;
}
export const MenuContainer = styled.ul<IMenuContainerProps>`
  transition: 0.25s;
  height: ${({ height, open }) => (open ? `${height}px` : 0)};
  display: flex;
  flex-direction: column;
  padding-top: ${sizes.spacing.xsm};
  padding-bottom: ${sizes.spacing.xsm};
  overflow-y: hidden;
  > li {
    transition: 0.25s;
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  }
`;
