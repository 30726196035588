import styled, { css } from 'styled-components';

import { sizes } from '~/common/constants/sizes';

interface ITextInputContainerProps {
  readonly error: boolean;
  readonly width: string;
  readonly disabled: boolean;
  readonly isColorInput?: boolean;
  readonly reverseColor: boolean;
}
export const TextInputContainer = styled.div<ITextInputContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};

  > div {
    position: relative;
    width: 100%;
    > span {
      transition: 0.25s;
    }

    textarea {
      resize: vertical;
      padding-top: ${sizes.spacing.sm};
      min-height: 120px;
    }

    > input,
    textarea {
      width: 100%;
      height: ${sizes.inputs.xlg};
      border: 1px solid
        ${({ theme, error }) => (error ? theme.red : theme.input_border)};
      background-color: ${({ theme, disabled }) =>
        !disabled ? theme.input : theme.background};
      color: ${({ theme, reverseColor }) =>
        reverseColor ? theme.text_reverse : theme.text} !important;
      border-radius: ${sizes.border.sm};
      ${({ isColorInput }) =>
        !isColorInput &&
        css`
          padding-left: ${sizes.spacing.md};
          padding-right: ${sizes.spacing.md};
        `}

      transition: 0.25s;
      &:focus {
        border-color: ${({ theme }) => theme.green};
      }
    }

    #icon-wrapper {
      position: absolute;
      right: 0;
      bottom: 6px;
      margin-right: 10px;
      opacity: 0.75;
      cursor: pointer;
    }

    #select-currency-wrapper {
      position: absolute;
      left: 0;
      bottom: 3px;
      margin-left: 10px;
    }
  }
`;
