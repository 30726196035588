import { FC, createElement } from 'react';

import {
  IModalConfig,
  IModalProvidedProps,
  useModal,
} from '~/common/hooks/use-modal';
import { useMediaQuery } from '~/common/hooks/use-media-query';

import { Modal } from '..';

type TModal = {
  modal?: IModalProvidedProps;
  handleClose: () => void;
};

export const ModalRoot: FC<TModal> = ({ modal, handleClose }) => {
  const isMobile = useMediaQuery('(max-width: 750px)');
  const { closeModal } = useModal();

  if (!modal) return null;
  const { component, config } = modal || {};
  const { modalProps, props } = config || {};

  return (
    <Modal
      onClose={handleClose}
      {...modalProps}
      {...(isMobile && {
        closeOnClickOutside: false,
        closeOnEsc: false,
      })}
    >
      {!!component &&
        createElement<IModalConfig>(component, {
          onClose: closeModal,
          handleClose,
          ...(props || {}),
        })}
    </Modal>
  );
};
