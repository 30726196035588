/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import { ThemeProvider as SCThemeProvider } from 'styled-components';

import { themes } from '../styles/themes';
import { GlobalStyle } from '../styles/global';
import { IThemeTypes } from '../interfaces/i-theme-types';
import { IFCWithChildren } from '../interfaces/i-fc-with-children';

interface IThemeContext {
  readonly theme: keyof IThemeTypes;
  readonly setTheme: Dispatch<SetStateAction<keyof IThemeTypes>>;
}

const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

const ThemeProvider: IFCWithChildren = ({ children }) => {
  const [theme, setTheme] = useState<keyof IThemeTypes>('dark');

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      <SCThemeProvider theme={themes[theme]}>
        <GlobalStyle />
        {children}
      </SCThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export { useTheme, ThemeProvider };
