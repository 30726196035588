import styled from 'styled-components';

import { sizes } from '~/common/constants/sizes';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  width: 280px;
  padding: ${sizes.spacing.md};
  background-color: ${({ theme }) => theme.background_darker};

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 100px);
    height: calc(100% - 150px);
  }
`;

export const HeaderContainer = styled.div`
  @media (max-width: 750px) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5%;
  padding-left: 5%;
  height: 50px;
  > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const MenuListContainer = styled.ul`
  width: 100%;
  height: 100%;
  overflow-y: visible;
  overflow-x: hidden;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  gap: ${sizes.spacing.xsm};
`;

export const SidebarFooter = styled.ul`
  display: flex;
  flex-direction: column;
  height: 150px;

  > hr {
    color: ${({ theme }) => theme.text};
    opacity: 0.25;
  }
`;
