import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export interface EnviromentVariables {
  API_URL: string;
  STRIPE_CUSTOMER_PORTAL_URL: string;
  STRIPE_PRICING_TABLE_ID: string;
  STRIPE_PRICING_TABLE_NO_TRIAL_ID: string;
  STRIPE_PUBLISHABLE_KEY: string;
  RADAR_MONITOR_SOCKET_URL: string;
}

export const env: EnviromentVariables = publicRuntimeConfig;
