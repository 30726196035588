import { ISizesTypes } from '../interfaces/i-sizes-types';
import { ISizesOptions } from '../interfaces/i-sizes-options';

const inputs: ISizesOptions = {
  xsm: '20px',
  sm: '25px',
  md: '30px',
  lg: '35px',
  xlg: '40px',
};

const border: ISizesOptions = {
  xsm: '2px',
  sm: '5px',
  md: '8px',
  lg: '25px',
  xlg: '50%',
};

const spacing: ISizesOptions = {
  xsm: '5px',
  sm: '8px',
  md: '12px',
  lg: '20px',
  xlg: '40px',
};

export const typograph: ISizesOptions = {
  xsm: '12px',
  sm: '14px',
  md: '18px',
  lg: '26px',
  xlg: '34px',
};

export const sizes: ISizesTypes = {
  border,
  spacing,
  typograph,
  inputs,
};
